import {AppLink, coloredIcons} from "@frontai/design-system"
import React from "react"
import styled from "styled-components"

const StyledAppList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  & > li {
    display: inline-block;
    width: 50%;

    & > a {
      width: 100%;
    }

    a > img {
      height: 3.5em;
      max-width: 90%;
    }

    @media screen and (min-width: 22.5em) {
      width: 33%;
    }
  }
`

export const AppList = ({apps}) => (
  <nav>
    <StyledAppList>
      {apps.map(app => {
        const config = app.app_instance_config

        const Icon = config.icon
          ? coloredIcons[config.icon]
          : () => <img src={config.logo_src} alt={app.app_instance_name} />

        const props = config.is_external
          ? {
              href: app_src,
              target: "_blank"
            }
          : {href: `/${app.app_instance_id}/`}

        return (
          <li key={app.app_instance_id}>
            <AppLink
              icon={<Icon />}
              data-cy={"app-" + app.app_instance_id}
              {...props}
            >
              {app.app_instance_name}
            </AppLink>
          </li>
        )
      })}
    </StyledAppList>
  </nav>
)
