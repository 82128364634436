import React from "react"
import PropTypes from "prop-types"
import {ThemeProvider as StyledThemeProvider} from "styled-components"

export const ThemeProvider = ({theme, children, ...optional}) => (
  <StyledThemeProvider theme={theme} {...optional}>
    {children}
  </StyledThemeProvider>
)

ThemeProvider.propTypes = {
  theme: PropTypes.object.isRequired
}
