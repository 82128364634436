import {Toast, Toaster as Toasts, monochromeIcons} from "@frontai/design-system"
import React from "react"
import {useSelector, useDispatch} from "react-redux"
import {
  getNotifications,
  removeNotification
} from "@frontai/atlas/features/notifications/notifications.store"
import {notificationTypes} from "@frontai/atlas/features/notifications/notifications.utils"

const toastThemes = {
  [notificationTypes.INFO]: {
    icon: <monochromeIcons.Info />
  },
  [notificationTypes.ALERT]: {
    icon: <monochromeIcons.Alert />
  },
  [notificationTypes.SUCCESS]: {
    icon: <monochromeIcons.Info />,
    bg: "success"
  }
}

export const Toaster = () => {
  const toasts = useSelector(getNotifications)
  const dispatch = useDispatch()

  return (
    <Toasts>
      {toasts.map(({id, message, notificationProps, onClose}) => (
        <Toast
          key={id}
          onClose={() => {
            typeof onClose === "function" && onClose()
            dispatch(removeNotification(id))
          }}
          {...(toastThemes[notificationProps.type] ||
            toastThemes[notificationTypes.INFO])}
          {...notificationProps}
        >
          {message}
        </Toast>
      ))}
    </Toasts>
  )
}
