import {useState, useEffect, useMemo} from "react"
import {theme as defaultTheme, modes as defaultModes} from "."
import * as R from "ramda"
import {createTheme} from "react-data-table-component"

const DEFAULT_COLOR_MODE = "light"

export const useTheme = (
  initialColorMode,
  themeOverrides = {},
  colorModeOverrides = {}
) => {
  const theme = useMemo(() => R.mergeDeepRight(defaultTheme, themeOverrides), [
    themeOverrides
  ])

  const colorModes = useMemo(
    () => R.mergeDeepRight(defaultModes, colorModeOverrides),
    [colorModeOverrides]
  )

  const [colorMode, setMode] = useState(initialColorMode ?? DEFAULT_COLOR_MODE)
  const [currentTheme, setTheme] = useState({
    ...theme,
    ...colorModes[colorMode]
  })

  const toggleColorMode = () => {
    const modes = Object.keys(colorModes)
    const modeIndex = modes.indexOf(colorMode)
    const nextModeIndex = modeIndex + 1 < modes.length ? modeIndex + 1 : 0
    const newMode = modes[nextModeIndex]
    setMode(newMode)
    return newMode
  }

  useEffect(() => {
    setTheme({
      ...theme,
      ...colorModes[colorMode]
    })
  }, [colorMode])

  return [currentTheme, colorMode, toggleColorMode, setMode]
}
