import {
  defaultBaseConfig,
  storageKeys,
  WINDOW_CONFIG_KEY
} from "@frontai/atlas/settings"
import {appConfigurationReducer} from "@frontai/atlas/features/app-configuration/app-configuration.store"
import {notificationsReducer} from "@frontai/atlas/features/notifications/notifications.store"
import {userSessionReducer} from "@frontai/atlas/features/user-session/user-session.store"
import {getItemAsJSON, setItemAsJSON} from "@frontai/atlas/utils/storage.utils"
import {combineReducers, createStore} from "redux"

// Get initial base config
let cachedBaseConfig = getItemAsJSON(sessionStorage, storageKeys.baseConfig)
if (!cachedBaseConfig) {
  const initialBaseConfig = window[WINDOW_CONFIG_KEY] || defaultBaseConfig
  setItemAsJSON(sessionStorage, storageKeys.baseConfig, initialBaseConfig)
  cachedBaseConfig = initialBaseConfig
}

// Store the initial query string - updated on every full reload
const queryString = window.location.search
setItemAsJSON(sessionStorage, storageKeys.queryParameters, queryString)

const mode = getItemAsJSON(localStorage, storageKeys.colorMode)

const initialState = {
  appConfigurationReducer: {
    queryString,
    mode,
    baseConfig: cachedBaseConfig
  }
}

const portalReducer = combineReducers({
  appConfigurationReducer,
  userSessionReducer,
  notificationsReducer
})

export const store = createStore(portalReducer, initialState)
