import {
  AppLink,
  coloredIcons,
  Container,
  Heading,
  Panel
} from "@frontai/design-system"
import React from "react"
import {AppList} from "../../components/app-list/app-list"

export const AppSelection = ({apps}) => (
  <Container
    // @ts-ignore
    maxWidth="modalMax"
    my="6"
    px={[2, 0]}
    textAlign="center"
  >
    <Panel>
      <Heading level="1" variant={[3, 2]}>
        Select App
      </Heading>

      <AppList apps={apps} />
    </Panel>
  </Container>
)
