import {
  getBaseConfig,
  getColorMode,
  updateColorMode
} from "@frontai/atlas/features/app-configuration/app-configuration.store"
import {
  usePollSessionStatus,
  usePromptSessionRefresh
} from "@frontai/atlas/features/user-session/user-session.hooks"
import {getSessionStatus} from "@frontai/atlas/features/user-session/user-session.store"
import {SESSION_STATUS} from "@frontai/atlas/features/user-session/user-session.utils"
import {storageKeys} from "@frontai/atlas/settings"
import {setItemAsJSON} from "@frontai/atlas/utils/storage.utils"
import {
  AbsoluteSpinnerWrapper,
  GlobalStyle,
  Spinner,
  useTheme
} from "@frontai/design-system"
import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {BrowserRouter} from "react-router-dom"
import {ThemeProvider} from "styled-components"
import {Toaster} from "../../components/toaster/toaster"
import {LandingPage} from "../landing-page/landing-page"
import {Login} from "../login/login"

export const Portal = () => {
  usePollSessionStatus()
  usePromptSessionRefresh()
  const dispatch = useDispatch()
  const sessionStatus = useSelector(getSessionStatus)
  const configuration = useSelector(getBaseConfig)
  const initialColorMode = useSelector(getColorMode)
  const [currentTheme, currentColorMode, toggleColorMode] = useTheme(
    initialColorMode,
    configuration?.obj_base_config?.obj_theme_overrides,
    configuration?.obj_base_config?.obj_color_mode_overrides
  )

  useEffect(() => {
    setItemAsJSON(localStorage, storageKeys.colorMode, currentColorMode)
    dispatch(updateColorMode(currentColorMode))
  }, [currentColorMode])

  return (
    <ThemeProvider theme={currentTheme}>
      <BrowserRouter>
        <GlobalStyle />
        <Toaster />
        {sessionStatus === undefined && (
          <AbsoluteSpinnerWrapper>
            <Spinner>Loading</Spinner>
          </AbsoluteSpinnerWrapper>
        )}
        {sessionStatus === SESSION_STATUS.expired && (
          <Login toggleColorMode={toggleColorMode} />
        )}
        {(sessionStatus === SESSION_STATUS.live ||
          sessionStatus === SESSION_STATUS.aboutToExpire) && (
          <LandingPage toggleColorMode={toggleColorMode} />
        )}
      </BrowserRouter>
    </ThemeProvider>
  )
}
