import {endpoints} from "@frontai/atlas/settings"
import {getQueryString} from "@frontai/atlas/features/app-configuration/app-configuration.store"
import {
  Box,
  Button,
  Heading,
  Label,
  Logo,
  Panel,
  Paragraph,
  TextField,
  Container
} from "@frontai/design-system"
import PropTypes from "prop-types"
import React, {useState} from "react"
import {useSelector} from "react-redux"
import {useDomain} from "./login.hooks"
import {getOrganizationFromQueryString} from "./login.utils"
import {ViewWrapper} from "../../components/view-wrapper/view-wrapper"
import {UnauthenticatedHeader} from "../../components/header/unauthenticated-header"

export const Login = ({toggleColorMode}) => {
  const queryString = useSelector(getQueryString)
  const [orgId, setOrgId] = useState(
    getOrganizationFromQueryString(queryString)
  )
  const [email, setEmail] = useState("")
  const domain = useDomain(email)
  const handleSubmit = event => {
    event.preventDefault()
    window.top.location.href = `${endpoints.idp.idpDiscovery}?domain=${domain}&org_id=${orgId}`
  }

  return (
    <ViewWrapper
      header={<UnauthenticatedHeader toggleColorMode={toggleColorMode} />}
    >
      <Container
        // @ts-ignore
        maxWidth="modalMax"
        px={[2, 0]}
        mb={6}
        textAlign="center"
      >
        <div>
          <Logo
            // @ts-ignore
            my={[5, 6]}
            maxWidth={["10rem", "15rem"]}
          />
        </div>
        <Panel>
          <Heading level="1" variant={[3, 2]}>
            Welcome to Atlas Portal
          </Heading>
          <Paragraph mb={5}>
            Enter your organization ID and e-mail address to access Atlas
            portal. You will be redirected to your organization’s login page.
          </Paragraph>

          <form
            method="GET"
            action={endpoints.idp.idpDiscovery}
            onSubmit={handleSubmit}
          >
            <Box mb={5}>
              <input type="hidden" value={domain} name="domain" />

              <Label
                htmlFor="oid"
                mr={3}
                width={["100%", "7.5em"]}
                textAlign={["left", "right"]}
              >
                Organization ID
              </Label>
              <TextField
                id="org_id"
                name="org_id"
                type="text"
                data-cy="oid"
                value={orgId}
                onChange={event => setOrgId(event.currentTarget.value)}
                required
              />
            </Box>

            <Box mb={5}>
              <Label
                htmlFor="email"
                mr={3}
                width={["100%", "7.5em"]}
                textAlign={["left", "right"]}
              >
                E-mail
              </Label>
              <TextField
                id="email"
                name="email"
                type="email"
                data-cy="email"
                maxlength={36}
                value={email}
                onChange={event => setEmail(event.currentTarget.value)}
                required
              />
            </Box>

            <Box mb={5}>
              <Button data-cy="login" large>
                Proceed to login
              </Button>
            </Box>
          </form>
        </Panel>
      </Container>
    </ViewWrapper>
  )
}

Login.propTypes = {
  toggleColorMode: PropTypes.func.isRequired
}
