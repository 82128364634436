import {throttle} from "@frontai/atlas/utils/events"
import {
  AppHeader,
  AppHeaderButton,
  monochromeIcons
} from "@frontai/design-system"
import PropTypes from "prop-types"
import React, {useCallback, useState} from "react"
import {MainMenu} from "../main-menu/main-menu"

export const AuthenticatedHeader = ({
  toggleColorMode,
  user,
  project,
  apps,
  onClickChangeProject
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const setMenuVisibility = useCallback(throttle(60, setIsMenuVisible), [])

  return (
    <>
      <AppHeader>
        <AppHeaderButton
          icon={<monochromeIcons.LightSwitch />}
          onClick={toggleColorMode}
          data-cy="change-theme"
        >
          Lights
        </AppHeaderButton>
        <AppHeaderButton
          icon={<monochromeIcons.Menu />}
          onClick={() => setMenuVisibility(!isMenuVisible)}
          data-cy="open-menu"
        >
          Menu
        </AppHeaderButton>
      </AppHeader>

      {isMenuVisible && (
        <MainMenu
          onClose={() => setMenuVisibility(false)}
          onClickChangeProject={onClickChangeProject}
          user={user}
          project={project}
          apps={apps}
        />
      )}
    </>
  )
}

AuthenticatedHeader.propTypes = {
  toggleColorMode: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  project: PropTypes.object,
  apps: PropTypes.arrayOf(PropTypes.object)
}
