import {storageKeys} from "@frontai/atlas/settings"
import {
  GlobalStyle,
  ThemeProvider,
  useTheme,
  AbsoluteSpinnerWrapper,
  Spinner
} from "@frontai/design-system"
import * as R from "ramda"
import React, {useEffect} from "react"
import {Provider, useSelector} from "react-redux"
import {BrowserRouter, withRouter} from "react-router-dom"
import {combineReducers, createStore} from "redux"
import {getItemAsJSON} from "../../utils/storage.utils"
import {messageTypes, sendMessage} from "../messaging/messaging.utils"
import {
  useFrameMessaging,
  useSyncLocationWithParentFrame
} from "./embedded-app.hooks"
import {
  embeddedAppReducer,
  getAppConfig,
  getBaseConfig,
  getColorMode
} from "./embedded-app.store"

const findByInstanceId = id => R.find(R.propEq("app_instance_id", id))

export const getAppById = (apps, id) => {
  const findApp = findByInstanceId(id)
  return findApp(apps)
}

export const getAppIdFromUrl = url => {
  console.log(url)
  debugger
  return "foo"
}

export const getEmbeddedAppInitialState = () => {
  const baseConfig = getItemAsJSON(sessionStorage, storageKeys.baseConfig)
  const queryString = getItemAsJSON(sessionStorage, storageKeys.queryParameters)
  const mode = getItemAsJSON(localStorage, storageKeys.colorMode)

  return {
    baseConfig,
    queryString,
    mode
  }
}

export const getEmbeddedAppStore = (reducers = {}, initialState = {}) => {
  const defaultInitialState = getEmbeddedAppInitialState()

  return createStore(
    combineReducers({
      embeddedAppReducer,
      ...reducers
    }),
    {
      embeddedAppReducer: {
        ...defaultInitialState
      },
      ...initialState
    }
  )
}

const EmbeddedAppLoader = withRouter(({location, children}) => {
  const appConfig = useSelector(getAppConfig)
  useFrameMessaging()
  useSyncLocationWithParentFrame(location)

  if (!appConfig) {
    return (
      <AbsoluteSpinnerWrapper>
        <Spinner>Loading</Spinner>
      </AbsoluteSpinnerWrapper>
    )
  }

  return children
})

const EmbeddedAppThemeProvider = ({children}) => {
  const mode = useSelector(getColorMode)
  const configuration = useSelector(getBaseConfig)
  const [theme, _currentColorMode, _toggleColorMode, setMode] = useTheme(
    mode,
    configuration?.obj_base_config?.obj_theme_overrides,
    configuration?.obj_base_config?.obj_color_mode_overrides
  )

  useEffect(() => {
    setMode(mode)
  }, [mode])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export const asEmbeddedApp = (Component, store) => () => {
  return (
    <Provider store={store}>
      <EmbeddedAppThemeProvider>
        <BrowserRouter>
          <GlobalStyle />
          <EmbeddedAppLoader>
            <Component />
          </EmbeddedAppLoader>
        </BrowserRouter>
      </EmbeddedAppThemeProvider>
    </Provider>
  )
}

export const sendNotificationToParent = (message, notificationProps = {}) => {
  sendMessage(window.parent, messageTypes.NOTIFY_USER, {
    message,
    notificationProps
  })
}
