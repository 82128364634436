import {coloredIcons} from "@frontai/design-system"
import React from "react"

export const AUTO_SELECT_PROJECT = true
export const SESSION_STATUS_POLL_INTERVAL_MS = 30000

export const defaultApps = {
  login: {
    id: "nexus",
    name: "Nexus Login",
    src: "/apps/nexus/"
  },
  catalogue: {
    id: "catalogue",
    name: "App Selection",
    src: "/apps/catalogue/"
  }
}

export const appIcons = {
  "user-management": <coloredIcons.Organization />,
  "bot-system-management": <coloredIcons.BotManagement />,
  "client-solution-management": <coloredIcons.CustomerInsight />,
  support: <coloredIcons.CustomerSupport />,
  audit: <coloredIcons.Audit />,
  cms: <coloredIcons.Nodes />
}

export const defaultBaseConfig = {
  obj_base_config: {
    default_color_mode: "light",
    obj_theme_overrides: {},
    obj_color_mode_overrides: {}
  }
}

export const endpoints = {
  user: {
    info: "/api/user/info/",
    app: "/api/user/app/"
  },
  idp: {
    idpDiscovery: "/api/login/idp-discovery/",
    expire: "/api/login/expire/",
    check: "/api/login/check/",
    continue: "/api/login/continue/"
  }
}

export const storageKeys = {
  baseConfig: "atlasConfig",
  queryParameters: "atlasQuery",
  colorMode: "mode"
}

export const WINDOW_CONFIG_KEY = "ATLAS_CONFIG"
