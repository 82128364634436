import React from "react"
import PropTypes from "prop-types"
import {Link as RouterLink} from "react-router-dom"
import styled from "styled-components"
import {compose, typography, space} from "styled-system"
import css from "@styled-system/css"

const StyledLink = styled.a`
  display: inline-block;
  font-size: 1em;
  text-decoration: none;
  ${css({color: "link"})}

  ${compose(typography, space)}
`

export const Link = ({children, ...optional}) => {
  const props = {
    ...optional,
    as: optional.href ? "a" : RouterLink
  }

  return (
    // @ts-ignore: TS doesn't understand as-prop without typings
    <StyledLink {...props}>{children}</StyledLink>
  )
}

Link.propTypes = {
  /** Link target for internal links */
  to: PropTypes.string,
  /** Link target for external links */
  href: PropTypes.string
}
