/**
 * This store contains any notifications which should be displayed to the user
 */

import {createAction, handleActions} from "redux-actions"
import {createSelector} from "reselect"

/**
 * Actions
 */

export const addNotification = createAction(
  "ADD_NOTIFICATION",
  (message, notificationProps = {}, onClose) => ({
    message,
    notificationProps,
    onClose,
    id: +new Date()
  })
)
export const removeNotification = createAction("REMOVE_NOTIFICATION", id => ({
  id
}))

/**
 * Reducer
 */

export const notificationsReducer = handleActions(
  {
    [addNotification]: (state, {payload, error}) => {
      return {
        ...state,
        notifications: [...state.notifications, payload],
        hasError: error
      }
    },
    [removeNotification]: (state, {payload, error}) => {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== payload.id
        ),
        hasError: error
      }
    }
  },
  {
    notifications: []
  }
)

/**
 * Selectors
 */

export const getNotificationsState = state => state.notificationsReducer
export const getNotifications = createSelector(
  getNotificationsState,
  notifications => notifications.notifications
)
