import {keyframes} from "styled-components"

export const blur = keyframes`
  from {filter: blur(0px)}
  to {filter: blur(2px)}
`

export const appear = keyframes`
  from {filter: opacity(0)}
  to {filter: opacity(1)}
`
