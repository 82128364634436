import React from "react"
import PropTypes from "prop-types"
import * as R from "ramda"
import styled, {withTheme} from "styled-components"
import {compose, typography, space} from "styled-system"
import {propFromScale} from "../core/utils"

const SVG = styled.svg`
  width: 1em;
  height: 1em;
  ${compose(typography, space)}
`

const twoToneIconPropTypes = {
  tone1: PropTypes.string,
  tone2: PropTypes.string,
  outline: PropTypes.string
}

const twoToneIconDefaultProps = {
  tone1: "brand",
  tone2: "accent",
  outline: "iconOutline"
}

const asTwoToneIcon = renderSvg => {
  const TwoToneIcon = withTheme(props => {
    const tone1 = propFromScale("tone1", "colors", props)
    const tone2 = propFromScale("tone2", "colors", props)
    const outline = propFromScale("outline", "colors", props)

    return renderSvg(tone1, tone2, outline, props)
  })

  TwoToneIcon.propTypes = twoToneIconPropTypes
  TwoToneIcon.defaultProps = twoToneIconDefaultProps

  return TwoToneIcon
}

export const Analytics = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: tone1}}
      d="M239.35,238.7V33.3c-126.459,0-228.943,102.484-228.943,228.943
	c0,57.219,21.046,109.501,55.757,149.64L239.35,238.7z"
    />
    <path
      style={{fill: tone2}}
      d="M262.894,262.244L89.708,435.428c40.133,34.708,92.411,55.757,149.642,55.757
	c126.459,0,228.943-102.525,228.943-228.943H262.894V262.244z"
    />
    <g>
      <rect
        x="176.922"
        y="218.547"
        style={{fill: outline}}
        width="20.813"
        height="20.813"
      />
      <rect
        x="135.286"
        y="218.547"
        style={{fill: outline}}
        width="20.813"
        height="20.813"
      />
      <rect
        x="93.658"
        y="218.547"
        style={{fill: outline}}
        width="20.813"
        height="20.813"
      />
      <path
        style={{fill: outline}}
        d="M309.082,153.672L413.914,48.837c-5.53-4.342-11.256-8.43-17.175-12.257L289.864,143.455
		C296.589,146.317,302.999,149.757,309.082,153.672z"
      />
      <path
        style={{fill: outline}}
        d="M272.65,0v29.432l27.702-27.704C291.267,0.63,282.033,0,272.65,0z"
      />
      <path
        style={{fill: outline}}
        d="M358.131,211.735l106.883-106.883c-3.825-5.919-7.915-11.645-12.257-17.175L347.919,192.515
		C351.831,198.597,355.267,205.012,358.131,211.735z"
      />
      <path
        style={{fill: outline}}
        d="M321.039,5.171L272.65,53.56v29.432l71.532-71.537C336.63,8.975,328.914,6.861,321.039,5.171z"
      />
      <path
        style={{fill: outline}}
        d="M337.649,178.662L441.728,74.58c-4.682-5.123-9.592-10.033-14.715-14.715L322.931,163.948
		C328.22,168.451,333.143,173.373,337.649,178.662z"
      />
      <path
        style={{fill: outline}}
        d="M499.866,201.239l-27.702,27.702h29.43C501.594,219.561,500.961,210.324,499.866,201.239z"
      />
      <path
        style={{fill: outline}}
        d="M490.138,157.409l-71.532,71.532h29.432l48.389-48.389
		C494.733,172.68,492.618,164.964,490.138,157.409z"
      />
      <path
        style={{fill: outline}}
        d="M474.04,119.951L365.051,228.943h29.43l89.121-89.121
		C480.727,133.025,477.532,126.395,474.04,119.951z"
      />
      <path
        style={{fill: outline}}
        d="M361.777,17.992l-89.126,89.121v29.432L381.642,27.553
		C375.201,24.061,368.573,20.867,361.777,17.992z"
      />
      <rect
        x="387.789"
        y="491.187"
        style={{fill: outline}}
        width="80.504"
        height="20.813"
      />
      <polygon
        style={{fill: outline}}
        points="468.293,471.675 383.48,471.675 323.505,411.699 338.227,396.984 392.097,450.862
		468.293,450.862 	"
      />
      <rect
        x="10.406"
        y="12.488"
        style={{fill: outline}}
        width="80.504"
        height="20.813"
      />
      <polygon
        style={{fill: outline}}
        points="140.472,127.503 86.602,73.626 10.406,73.626 10.406,52.813 95.219,52.813 155.195,112.788
			"
      />
    </g>
  </SVG>
))

export const Audit = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <g>
      <path
        style={{fill: outline}}
        d="M392.253,512H11.546V0h294.61l86.098,86.106V512z M31.583,491.963h340.636V94.403l-74.358-74.365
		H31.583V491.963z"
      />
      <polygon
        style={{fill: outline}}
        points="382.228,100.257 291.991,100.257 291.991,10.02 312.026,10.02 312.026,80.22 382.228,80.22
			"
      />
      <rect
        x="73.659"
        y="80.23"
        style={{fill: outline}}
        width="128.23"
        height="20.037"
      />
      <rect
        x="71.657"
        y="271.565"
        style={{fill: outline}}
        width="260.48"
        height="20.037"
      />
      <rect
        x="71.657"
        y="311.629"
        style={{fill: outline}}
        width="260.48"
        height="20.037"
      />
      <rect
        x="71.657"
        y="351.693"
        style={{fill: outline}}
        width="260.48"
        height="20.037"
      />
      <rect
        x="71.657"
        y="391.782"
        style={{fill: outline}}
        width="260.48"
        height="20.037"
      />
      <rect
        x="71.657"
        y="431.846"
        style={{fill: outline}}
        width="260.48"
        height="20.037"
      />
    </g>
    <g>
      <polygon
        style={{fill: tone2}}
        points="88.758,233.062 74.593,218.895 135.112,158.382 175.186,198.454 228.618,145.019
		268.692,185.096 315.041,138.747 329.208,152.914 268.692,213.43 228.618,173.353 175.186,226.79 135.112,186.716 	"
      />
      <polygon
        style={{fill: tone2}}
        points="332.142,186.755 312.105,186.755 312.105,155.848 281.201,155.848 281.201,135.811
		332.142,135.811 	"
      />
    </g>
    <g>
      <polygon
        style={{fill: outline}}
        points="500.454,471.926 422.31,471.926 422.31,451.889 480.417,451.889 480.417,276.073
		500.454,276.073 	"
      />
      <path
        style={{fill: outline}}
        d="M472.402,512h-60.111V86.09l30.057-30.057l30.054,30.057V512z M432.328,491.963h20.037V94.387
		l-10.017-10.02l-10.02,10.02V491.963z"
      />
      <rect
        x="422.323"
        y="266.061"
        style={{fill: outline}}
        width="40.074"
        height="20.035"
      />
      <rect
        x="432.333"
        y="50.13"
        style={{fill: outline}}
        width="20.037"
        height="20.073"
      />
    </g>
  </SVG>
))

export const BotManagement = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: outline}}
      d="M418.253,512L394.67,368.96l39.677-130.304c6.029-17.592,9.029-35.681,9.029-54.075
	c0-91.54-74.468-166.019-166.006-166.019c-91.556,0-166.034,74.476-166.034,166.019l-0.118,22.62L76.017,310.18h41.277
	l8.745,110.093l87.588-8.049l11.978,97.134l-18.424,2.273l-9.777-79.276l-88.384,8.12l-8.878-111.731H50.058l42.614-124.68
	l0.105-19.53C92.774,82.803,175.585,0,277.373,0c101.77,0,184.568,82.803,184.568,184.581c0,20.447-3.343,40.561-9.93,59.786
	l-38.318,125.852l22.881,138.76L418.253,512z"
    />
    <path
      style={{fill: tone2}}
      d="M379.443,192.335v-15.516l-22.147-4.462c-0.924-5.962-2.504-11.709-4.639-17.175l16.98-15.002
	l-7.716-13.315l-21.455,7.217c-3.763-4.649-7.972-8.878-12.652-12.595l7.247-21.514l-13.368-7.695l-14.979,17.037
	c-5.445-2.135-11.172-3.712-17.134-4.611l-4.48-22.236h-15.468l-4.47,22.236c-5.972,0.893-11.707,2.468-17.175,4.608L233.024,92.28
	l-13.384,7.695l7.242,21.532c-4.636,3.72-8.886,7.944-12.585,12.588l-21.509-7.227l-7.713,13.315l17.019,15.04
	c-2.135,5.445-3.715,11.174-4.59,17.116l-22.262,4.483l0.033,15.516l22.228,4.449c0.876,5.957,2.455,11.694,4.603,17.149
	l-17.032,15.009l7.713,13.33l21.509-7.255c3.699,4.654,7.951,8.878,12.588,12.608l-7.247,21.509l13.384,7.716l14.948-17.037
	c5.473,2.14,11.22,3.707,17.185,4.616l4.477,22.264H285.1l4.488-22.272c5.962-0.909,11.692-2.486,17.162-4.608l14.968,17.037
	l13.343-7.716l-7.255-21.486c4.687-3.727,8.899-7.972,12.659-12.626l21.455,7.247l7.716-13.33l-16.988-14.981
	c2.135-5.46,3.72-11.22,4.644-17.198L379.443,192.335z M277.34,227.123c-23.475,0-42.527-19.046-42.527-42.547
	c0-23.491,19.052-42.537,42.527-42.537c23.508,0,42.537,19.046,42.537,42.537C319.877,208.074,300.849,227.123,277.34,227.123z"
    />
    <g>
      <path
        style={{fill: outline}}
        d="M277.34,236.403c-28.572,0-51.809-23.25-51.809-51.827c0-28.575,23.237-51.82,51.809-51.82
		c28.57,0,51.82,23.245,51.82,51.82C329.16,213.153,305.91,236.403,277.34,236.403z M277.34,151.319
		c-18.332,0-33.247,14.92-33.247,33.257c0,18.342,14.915,33.265,33.247,33.265c18.337,0,33.247-14.922,33.247-33.265
		C310.587,166.239,295.677,151.319,277.34,151.319z"
      />
      <path
        style={{fill: outline}}
        d="M267.617,329.055c-6.561-0.435-13.151-1.329-19.571-2.647l3.732-18.181
		c5.604,1.149,11.343,1.925,17.065,2.301L267.617,329.055z M287.347,329.032l-1.267-18.519c5.727-0.387,11.466-1.175,17.06-2.335
		l3.771,18.171C300.498,327.683,293.911,328.584,287.347,329.032z M228.992,321.103c-6.195-2.191-12.301-4.838-18.138-7.862
		l8.535-16.486c5.087,2.639,10.401,4.946,15.793,6.856L228.992,321.103z M325.939,321.014l-6.231-17.487
		c5.386-1.923,10.701-4.229,15.777-6.876l8.573,16.466C338.222,316.152,332.127,318.81,325.939,321.014z M193.964,302.976
		c-5.348-3.781-10.511-7.964-15.327-12.452l12.654-13.583c4.204,3.914,8.701,7.57,13.376,10.865L193.964,302.976z M360.906,302.835
		l-10.732-15.147c4.68-3.31,9.172-6.976,13.353-10.885l12.682,13.558C371.412,294.843,366.267,299.039,360.906,302.835z
		 M165.148,276.114c-4.157-5.087-8-10.506-11.425-16.105l15.836-9.684c2.985,4.882,6.339,9.608,9.961,14.047L165.148,276.114z
		 M389.683,275.93l-14.392-11.72c3.62-4.449,6.963-9.18,9.94-14.07l15.852,9.656C397.673,265.398,393.846,270.828,389.683,275.93z
		 M144.602,242.506c-2.634-6.029-4.874-12.291-6.651-18.614l17.869-5.028c1.546,5.504,3.5,10.954,5.791,16.202L144.602,242.506z
		 M410.181,242.258l-17.019-7.406c2.286-5.24,4.219-10.703,5.773-16.228l17.869,5C415.035,229.972,412.8,236.242,410.181,242.258z
		 M133.916,204.557c-0.896-6.479-1.359-13.105-1.367-19.697l18.563-0.031c0.008,5.755,0.41,11.538,1.193,17.183L133.916,204.557z
		 M420.805,204.273l-18.396-2.506c0.776-5.65,1.157-11.433,1.157-17.188v-0.53h18.573v0.53
		C422.139,191.173,421.688,197.798,420.805,204.273z M152.236,167.639l-18.396-2.478c0.881-6.528,2.209-13.033,3.96-19.351
		l17.887,4.959C154.163,156.273,153.004,161.943,152.236,167.639z M402.342,166.907c-0.794-5.678-1.984-11.341-3.553-16.837
		l17.864-5.069c1.792,6.313,3.159,12.818,4.07,19.336L402.342,166.907z M161.421,134.533l-17.042-7.37
		c2.606-6.021,5.652-11.93,9.068-17.554l15.875,9.618C166.354,124.134,163.689,129.28,161.421,134.533z M392.96,133.868
		c-2.294-5.228-4.977-10.353-7.972-15.235l15.813-9.715c3.443,5.604,6.531,11.492,9.165,17.487L392.96,133.868z M179.238,105.142
		l-14.413-11.694c4.124-5.087,8.643-9.948,13.437-14.451l12.713,13.53C186.783,96.458,182.84,100.703,179.238,105.142z
		 M375.007,104.604c-3.645-4.431-7.616-8.655-11.809-12.549l12.636-13.601c4.803,4.462,9.354,9.298,13.524,14.377L375.007,104.604z
		 M204.296,81.623l-10.755-15.135c5.358-3.802,11.021-7.276,16.832-10.317l8.596,16.453C213.911,75.272,208.97,78.3,204.296,81.623z
		 M349.814,81.219c-4.703-3.297-9.664-6.3-14.746-8.916l8.507-16.504c5.819,3.003,11.515,6.446,16.904,10.227L349.814,81.219z
		 M234.737,65.72l-6.262-17.475c6.185-2.217,12.585-4.027,19.018-5.373l3.807,18.166C245.696,62.213,240.125,63.785,234.737,65.72z
		 M319.26,65.477c-5.412-1.902-11-3.448-16.602-4.588l3.702-18.191c6.438,1.308,12.851,3.082,19.062,5.266L319.26,65.477z
		 M268.349,58.665l-1.3-18.516c6.531-0.458,13.174-0.481,19.73-0.061l-1.185,18.529C279.857,58.248,274.063,58.266,268.349,58.665z"
      />
    </g>
  </SVG>
))

export const Chat = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: tone2}}
      d="M112.289,319.179c-7.841-7.841-11.756-18.117-11.756-28.396V195.59H32.456
	c-8.307,0-16.612,3.169-22.948,9.505C3.169,211.439,0,219.743,0,228.051v153.636c0,8.305,3.169,16.614,9.505,22.953
	c6.339,6.333,14.643,9.503,22.948,9.503h8.566v44.106c0,1.27,0.753,2.478,1.999,2.995c1.247,0.515,2.634,0.197,3.535-0.699
	l46.392-46.4h199.058c8.305,0,16.612-3.169,22.95-9.503c6.346-6.339,9.513-14.648,9.513-22.953v-50.744H140.69
	C130.406,330.94,120.133,327.018,112.289,319.179z"
    />
    <path
      style={{fill: outline}}
      d="M447.204,399.555c-3.702,0-7.327-1.503-9.943-4.116l-54.454-54.459H140.69
	c-13.412,0-26.015-5.22-35.497-14.702c-9.48-9.477-14.7-22.085-14.7-35.492v-190.08c0-13.407,5.22-26.017,14.7-35.494
	c9.482-9.482,22.085-14.702,35.497-14.702h321.116c13.399,0,26.002,5.22,35.489,14.697C506.778,74.691,512,87.297,512,100.703
	v190.08c0,13.407-5.222,26.012-14.705,35.492c-9.487,9.482-22.09,14.702-35.489,14.702h-0.55v44.523
	c0,5.706-3.407,10.803-8.678,12.987C450.862,399.197,449.055,399.555,447.204,399.555z M140.69,70.588
	c-8.046,0-15.611,3.133-21.302,8.819c-5.686,5.691-8.817,13.251-8.817,21.299v190.08c0,8.049,3.131,15.608,8.817,21.297
	c5.691,5.688,13.256,8.819,21.302,8.819h250.432l50.056,50.056v-50.056h20.628c8.038,0,15.603-3.133,21.292-8.824
	c5.691-5.686,8.824-13.251,8.824-21.292v-190.08c0-8.044-3.136-15.608-8.824-21.299c-5.691-5.686-13.256-8.819-21.292-8.819
	L140.69,70.588L140.69,70.588z"
    />
    <rect
      x="148.71"
      y="108.737"
      style={{fill: tone1}}
      width="305.05"
      height="174.016"
    />
    <g>
      <rect
        x="256.077"
        y="185.701"
        style={{fill: outline}}
        width="18.074"
        height="20.078"
      />
      <rect
        x="292.224"
        y="185.701"
        style={{fill: outline}}
        width="18.074"
        height="20.078"
      />
      <rect
        x="328.346"
        y="185.701"
        style={{fill: outline}}
        width="18.074"
        height="20.078"
      />
    </g>
  </SVG>
))

export const Checklist = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <g>
      <polygon
        style={{fill: outline}}
        points="448.543,512 63.457,512 63.457,30.753 225.234,30.753 225.234,50.447 83.149,50.447
		83.149,492.308 428.851,492.308 428.851,50.447 286.774,50.447 286.774,30.753 448.543,30.753 	"
      />
      <polygon
        style={{fill: outline}}
        points="413.926,477.386 98.074,477.386 98.074,40.599 117.765,40.599 117.765,457.692
		394.235,457.692 394.235,40.599 413.926,40.599 	"
      />
      <path
        style={{fill: outline}}
        d="M333.158,112H178.849V30.769h37.747C221.007,13.115,237.005,0,256.003,0
		c19,0,34.995,13.115,39.409,30.769h37.745V112H333.158z M198.543,92.308h114.924V50.46h-36.536v-9.846
		c0-11.538-9.385-20.923-20.923-20.923c-11.538,0-20.923,9.385-20.923,20.923v9.846h-36.541V92.308z"
      />
      <rect
        x="256"
        y="345.882"
        style={{fill: outline}}
        width="110.618"
        height="19.692"
      />
      <rect
        x="256"
        y="385.28"
        style={{fill: outline}}
        width="55.311"
        height="19.692"
      />
      <rect
        x="256"
        y="164.71"
        style={{fill: outline}}
        width="110.618"
        height="19.692"
      />
      <rect
        x="256"
        y="204.109"
        style={{fill: outline}}
        width="55.311"
        height="19.692"
      />
      <rect
        x="256"
        y="255.309"
        style={{fill: outline}}
        width="110.618"
        height="19.692"
      />
      <rect
        x="256"
        y="294.682"
        style={{fill: outline}}
        width="55.311"
        height="19.692"
      />
    </g>
    <circle style={{fill: tone1}} cx="180.813" cy="194.26" r="35.446" />
    <polygon
      style={{fill: outline}}
      points="180.818,219.049 154.166,192.397 168.09,178.473 180.818,191.204 213.243,158.776
	227.164,172.7 "
    />
    <circle style={{fill: tone1}} cx="180.813" cy="284.851" r="35.446" />
    <polygon
      style={{fill: outline}}
      points="180.818,309.635 154.166,282.98 168.09,269.059 180.818,281.787 213.243,249.362
	227.164,263.286 "
    />
    <circle style={{fill: tone1}} cx="180.813" cy="375.424" r="35.446" />
    <polygon
      style={{fill: outline}}
      points="180.818,400.22 154.166,373.565 168.09,359.642 180.818,372.372 213.243,339.945
	227.164,353.869 "
    />
  </SVG>
))

export const CustomerInsight = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: outline}}
      d="M418.254,512l-23.585-143.04l39.68-130.309c6.024-17.587,9.027-35.676,9.027-54.07
	c0-91.546-74.47-166.019-166.008-166.019c-91.551,0-166.034,74.473-166.034,166.019l-0.115,22.615l-35.2,102.984h41.275
	l8.745,110.088l87.588-8.049l11.978,97.134l-18.427,2.271l-9.774-79.276l-88.387,8.12l-8.876-111.729H50.06l42.611-124.675
	l0.102-19.53C92.773,82.803,175.581,0,277.37,0C379.142,0,461.94,82.803,461.94,184.584c0,20.444-3.343,40.561-9.93,59.784
	L413.69,370.225l22.881,138.76L418.254,512z"
    />
    <path
      style={{fill: tone2}}
      d="M376.206,143.613c9.098-16.22,6.794-37.128-7.014-50.898c-13.814-13.814-34.675-16.113-50.9-7.055
	c-5.051-17.897-21.46-30.999-40.988-30.999s-35.937,13.105-40.988,30.999c-16.225-9.057-37.089-6.758-50.9,7.055
	c-13.778,13.77-16.113,34.675-7.02,50.898c-17.897,5.053-31.035,21.463-31.035,40.955c0,23.537,19.082,42.619,42.621,42.619h70.874
	c23.537,0,42.619,19.082,42.619,42.619v240.686h18.563V269.806c0-23.539,19.046-42.619,42.586-42.619
	c11.768,0,22.426-4.79,30.147-12.477c7.724-7.724,12.475-18.376,12.475-30.144C407.246,165.076,394.103,148.667,376.206,143.613z"
    />
    <g>
      <rect
        x="230.885"
        y="157.286"
        style={{fill: "#ffffff"}}
        width="18.563"
        height="18.563"
      />
      <rect
        x="268.031"
        y="157.286"
        style={{fill: "#ffffff"}}
        width="18.563"
        height="18.563"
      />
      <rect
        x="305.151"
        y="157.286"
        style={{fill: "#ffffff"}}
        width="18.563"
        height="18.563"
      />
    </g>
  </SVG>
))

export const Innovation = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: outline}}
      d="M418.257,512l-23.585-143.04l39.68-130.304c6.024-17.59,9.027-35.676,9.027-54.072
	c0-91.54-74.47-166.019-166.008-166.019c-91.551,0-166.031,74.478-166.031,166.019l-0.108,22.592L76.026,310.185h41.27
	l8.742,110.008l80.156-8.046l11.986,97.208l-18.422,2.278l-9.766-79.201l-80.957,8.125l-8.883-111.808H50.057l42.609-124.657
	v-28.792h0.338C97.855,77.816,178.697,0,277.372,0c101.773,0,184.571,82.803,184.571,184.584c0,20.447-3.338,40.563-9.93,59.786
	l-38.321,125.855l22.881,138.76L418.257,512z"
    />
    <path
      style={{fill: tone2}}
      d="M277.495,81.733c-56.822,0.01-102.861,46.049-102.861,102.871c0,30.093,12.948,57.17,33.539,75.986
	c0.97,0.812,1.951,1.667,2.959,2.552c16.072,14.666,26.163,35.745,26.163,59.192h80.402c0-23.447,10.069-44.529,26.186-59.192
	c0.98-0.886,1.958-1.741,2.936-2.552c20.564-18.819,33.533-45.893,33.533-75.986C380.353,127.782,334.319,81.743,277.495,81.733z"
    />
    <g>
      <polygon
        style={{fill: "#ffffff"}}
        points="277.321,115.904 277.321,190.561 245.027,190.561 	"
      />
      <polygon
        style={{fill: "#ffffff"}}
        points="277.321,253.274 277.321,178.606 309.6,178.606 	"
      />
    </g>
    <g>
      <rect
        x="228.865"
        y="313.062"
        style={{fill: outline}}
        width="97.249"
        height="18.565"
      />
      <rect
        x="228.865"
        y="342.963"
        style={{fill: outline}}
        width="97.249"
        height="18.565"
      />
      <rect
        x="255.054"
        y="372.89"
        style={{fill: outline}}
        width="44.884"
        height="18.565"
      />
      <rect
        x="268.212"
        y="402.816"
        style={{fill: outline}}
        width="18.563"
        height="107.674"
      />
      <path
        style={{fill: outline}}
        d="M194.589,302.147c-5.322-3.758-10.445-7.913-15.219-12.352l12.646-13.591
		c4.163,3.873,8.625,7.496,13.266,10.765L194.589,302.147z M360.329,301.998l-10.721-15.15c4.636-3.284,9.088-6.909,13.233-10.79
		l12.682,13.56C370.763,294.062,365.651,298.232,360.329,301.998z M165.964,275.492c-4.127-5.048-7.941-10.419-11.341-15.979
		l15.831-9.69c2.964,4.841,6.29,9.526,9.884,13.921L165.964,275.492z M388.893,275.279l-14.403-11.712
		c3.581-4.406,6.897-9.098,9.846-13.952l15.864,9.641C396.811,264.832,393.01,270.228,388.893,275.279z M145.558,242.132
		c-2.614-5.985-4.838-12.201-6.602-18.473l17.871-5.028c1.533,5.458,3.466,10.86,5.742,16.067L145.558,242.132z M409.22,241.836
		l-17.027-7.393c2.26-5.204,4.178-10.616,5.701-16.092l17.882,4.974C414.022,229.619,411.818,235.848,409.22,241.836z
		 M134.949,204.462c-0.893-6.428-1.349-13.002-1.364-19.543l18.563-0.044c0.015,5.711,0.415,11.443,1.188,17.044L134.949,204.462z
		 M419.729,204.124l-18.399-2.493c0.763-5.599,1.147-11.333,1.147-17.052l-0.005-0.527h18.565v0.333
		C421.042,191.13,420.599,197.706,419.729,204.124z M153.261,167.823l-18.401-2.465c0.87-6.469,2.189-12.936,3.93-19.213
		l17.889,4.961C155.165,156.57,154.016,162.194,153.261,167.823z M401.266,167.053c-0.791-5.64-1.974-11.259-3.52-16.704
		l17.861-5.066c1.772,6.262,3.139,12.716,4.042,19.19L401.266,167.053z M162.367,135.004l-17.037-7.368
		c2.586-5.983,5.617-11.848,9.004-17.434l15.872,9.626C167.254,124.692,164.617,129.8,162.367,135.004z M391.971,134.287
		c-2.281-5.197-4.943-10.284-7.913-15.117l15.823-9.713c3.41,5.553,6.469,11.4,9.088,17.367L391.971,134.287z M180.038,105.861
		l-14.415-11.689c4.106-5.061,8.591-9.889,13.345-14.354l12.703,13.535C187.526,97.239,183.617,101.448,180.038,105.861z
		 M374.153,105.265c-3.599-4.39-7.532-8.579-11.694-12.439l12.626-13.609c4.777,4.436,9.295,9.236,13.427,14.282L374.153,105.265z
		 M204.888,82.534l-10.755-15.127c5.315-3.786,10.934-7.229,16.707-10.245l8.599,16.448
		C214.414,76.239,209.522,79.242,204.888,82.534z M349.18,82.074c-4.662-3.274-9.58-6.246-14.615-8.84l8.497-16.504
		c5.783,2.977,11.43,6.392,16.786,10.148L349.18,82.074z M235.068,66.755l-6.264-17.472c6.134-2.202,12.483-3.999,18.867-5.34
		l3.822,18.166C245.938,63.276,240.411,64.842,235.068,66.755z M318.883,66.476c-5.348-1.882-10.888-3.412-16.461-4.541
		l3.689-18.194c6.41,1.3,12.78,3.059,18.934,5.225L318.883,66.476z M268.402,59.743l-1.313-18.516
		c3.379-0.241,6.81-0.358,10.194-0.358c0.031,0,0.054,0,0.079,0c3.082,0,6.216,0.097,9.311,0.294L285.5,59.689
		c-2.726-0.174-5.335-0.189-8.21-0.259C274.333,59.43,271.346,59.533,268.402,59.743z"
      />
    </g>
  </SVG>
))

export const Nodes = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <g>
      <path
        style={{fill: outline}}
        d="M477.35,512c-7.903,0-15.322-3.077-20.895-8.663c-5.571-5.568-8.643-12.982-8.643-20.874v-33.708
		h59.077v33.707c0,7.892-3.075,15.309-8.658,20.887C492.677,508.923,485.256,512,477.35,512z M467.505,468.444v14.019
		c0,2.629,1.021,5.102,2.88,6.961c3.72,3.722,10.22,3.722,13.916,0.013c1.874-1.871,2.898-4.342,2.898-6.973v-14.019H467.505z"
      />
      <polygon
        style={{fill: outline}}
        points="488.069,133.588 477.35,109.878 466.632,133.588 448.686,125.473 477.35,62.077
		506.015,125.473 	"
      />
      <path
        style={{fill: outline}}
        d="M497.042,468.444h-39.386c-5.437,0-9.846-4.408-9.846-9.846V129.531c0-5.44,4.408-9.846,9.846-9.846
		h39.386c5.44,0,9.848,4.408,9.848,9.846v329.068C506.89,464.038,502.482,468.444,497.042,468.444z M467.505,448.755h19.692V139.377
		h-19.692V448.755z"
      />
      <polygon
        style={{fill: outline}}
        points="81.213,445.719 61.519,445.719 61.519,19.692 185.347,19.692 185.347,39.386 81.213,39.386
			"
      />
      <path
        style={{fill: outline}}
        d="M361.866,512v-19.692c25.669,0,46.556-20.9,46.556-46.587V39.386H304.328V19.692h123.786v426.028
		C428.111,482.266,398.392,512,361.866,512z"
      />
      <path
        style={{fill: outline}}
        d="M361.841,512H71.38c-36.534,0-66.266-29.727-66.268-66.271l-0.003-9.846l310.157-0.015v9.846
		c0,25.692,20.892,46.592,46.574,46.592V512z M25.856,455.578c4.531,20.966,23.222,36.731,45.527,36.731h243.369
		c-9.664-9.772-16.317-22.531-18.45-36.746L25.856,455.578z"
      />
    </g>
    <path
      style={{fill: tone2}}
      d="M274.363,29.54c0,16.315-13.23,29.542-29.542,29.542c-16.307,0-29.54-13.228-29.54-29.542
	c0-16.31,13.233-29.54,29.54-29.54C261.133,0,274.363,13.23,274.363,29.54z"
    />
    <g>
      <rect
        x="212.992"
        y="324.608"
        style={{fill: tone1}}
        width="63.675"
        height="63.677"
      />
      <rect
        x="181.146"
        y="116.524"
        style={{fill: tone1}}
        width="127.355"
        height="63.677"
      />
      <rect
        x="311.168"
        y="324.608"
        style={{fill: tone1}}
        width="63.67"
        height="63.677"
      />
      <rect
        x="114.796"
        y="324.608"
        style={{fill: tone1}}
        width="63.677"
        height="63.677"
      />
    </g>
    <g>
      <path
        style={{fill: outline}}
        d="M352.855,301.238h-19.692V276.28c0-3.753-1.454-7.268-4.101-9.9
		c-2.665-2.675-6.18-4.134-9.928-4.134H170.516c-3.75,0-7.27,1.459-9.915,4.109c-2.662,2.657-4.124,6.175-4.124,9.925v24.957
		h-19.692V276.28c0-9.016,3.517-17.487,9.892-23.849c6.349-6.367,14.825-9.876,23.839-9.876h148.618
		c9.019,0,17.49,3.515,23.846,9.897c6.356,6.323,9.871,14.794,9.871,23.831v24.955H352.855z"
      />
      <rect
        x="234.982"
        y="203.571"
        style={{fill: outline}}
        width="19.692"
        height="97.69"
      />
      <path
        style={{fill: outline}}
        d="M286.5,398.124h-83.364v-83.366H286.5V398.124z M222.828,378.429h43.981v-43.986h-43.981V378.429z"
      />
      <path
        style={{fill: outline}}
        d="M318.346,190.047H171.3v-83.369h147.046L318.346,190.047L318.346,190.047z M190.991,170.353h107.663
		v-43.983H190.991V170.353z"
      />
      <path
        style={{fill: outline}}
        d="M384.694,398.124h-83.361v-83.366h83.361V398.124z M321.024,378.429H365v-43.986h-43.976V378.429z"
      />
      <path
        style={{fill: outline}}
        d="M188.321,398.124h-83.369v-83.366h83.369V398.124z M124.641,378.429h43.986v-43.986h-43.986V378.429
		z"
      />
    </g>
  </SVG>
))

export const Organization = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: tone1}}
      d="M347.325,217.678H164.669v-56.069c0-11.661,4.436-23.329,13.394-32.266
	c8.888-8.909,20.57-13.389,32.274-13.389h91.302c11.686,0,23.345,4.48,32.31,13.389c8.945,8.937,13.376,20.605,13.376,32.266
	L347.325,217.678L347.325,217.678z"
    />
    <g>
      <rect
        x="201.318"
        y="168.621"
        style={{fill: outline}}
        width="18.022"
        height="49.05"
      />
      <rect
        x="292.634"
        y="168.621"
        style={{fill: outline}}
        width="18.022"
        height="49.05"
      />
    </g>
    <path
      style={{fill: tone1}}
      d="M301.64,54.212c0,25.203-20.388,45.665-45.64,45.665c-25.219,0-45.663-20.462-45.663-45.665
	c0-25.198,20.444-45.663,45.663-45.663C281.252,8.549,301.64,29.014,301.64,54.212z"
    />
    <g>
      <path
        style={{fill: outline}}
        d="M442.486,327.195h-18.028c0-9.142-3.551-17.71-9.992-24.115c-6.456-6.484-15.017-10.04-24.141-10.04
		H121.69c-9.126,0-17.697,3.556-24.133,10.012c-6.464,6.451-10.022,15.017-10.022,24.143H69.507c0-13.949,5.437-27.052,15.304-36.89
		c9.823-9.856,22.93-15.293,36.879-15.293h268.636c13.949,0,27.046,5.44,36.884,15.314
		C437.051,300.108,442.486,313.212,442.486,327.195z"
      />
      <rect
        x="246.989"
        y="238.772"
        style={{fill: outline}}
        width="18.022"
        height="88.422"
      />
      <path
        style={{fill: outline}}
        d="M104.835,486.184c-0.476-3.154-1.912-6.003-4.152-8.243c-2.76-2.757-6.428-4.275-10.337-4.275
		H66.711c-3.904,0-7.572,1.518-10.332,4.278c-2.26,2.258-3.689,5.1-4.14,8.212l-17.846-2.578c1.011-6.989,4.206-13.345,9.231-18.381
		c6.17-6.17,14.367-9.559,23.086-9.559h23.634c8.719,0,16.914,3.389,23.086,9.551c4.982,4.984,8.174,11.31,9.229,18.294
		L104.835,486.184z"
      />
      <path
        style={{fill: outline}}
        d="M78.52,453.797c-15.916,0-28.867-12.948-28.867-28.861c0-15.918,12.951-28.872,28.867-28.872
		c15.923,0,28.877,12.956,28.877,28.872C107.397,440.849,94.444,453.797,78.52,453.797z M78.52,414.089
		c-5.972,0-10.839,4.861-10.839,10.844c0,5.975,4.867,10.834,10.839,10.834c5.983,0,10.849-4.859,10.849-10.834
		C89.37,418.95,84.503,414.089,78.52,414.089z"
      />
      <path
        style={{fill: outline}}
        d="M78.52,503.451C35.226,503.451,0,468.225,0,424.933c0-43.297,35.226-78.525,78.52-78.525
		c43.302,0,78.536,35.228,78.536,78.525C157.056,468.225,121.823,503.451,78.52,503.451z M78.52,364.438
		c-33.357,0-60.493,27.141-60.493,60.495s27.136,60.49,60.493,60.49c33.362,0,60.508-27.136,60.508-60.49
		S111.885,364.438,78.52,364.438z"
      />
      <path
        style={{fill: outline}}
        d="M282.307,486.184c-0.481-3.154-1.912-6.008-4.152-8.246c-2.752-2.749-6.426-4.273-10.33-4.273
		h-23.634c-3.912,0-7.585,1.518-10.348,4.278c-2.243,2.255-3.679,5.1-4.129,8.215l-17.838-2.583
		c1.006-6.986,4.196-13.343,9.219-18.371c6.177-6.177,14.374-9.567,23.099-9.567h23.634c8.709,0,16.906,3.389,23.078,9.549
		c4.984,4.992,8.166,11.313,9.226,18.294L282.307,486.184z"
      />
      <path
        style={{fill: outline}}
        d="M256,453.797c-15.923,0-28.874-12.948-28.874-28.861c0-15.918,12.951-28.872,28.874-28.872
		s28.877,12.956,28.877,28.872C284.877,440.849,271.923,453.797,256,453.797z M256,414.089c-5.978,0-10.844,4.861-10.844,10.844
		c0,5.975,4.867,10.834,10.844,10.834c5.983,0,10.847-4.859,10.847-10.834C266.847,418.95,261.983,414.089,256,414.089z"
      />
      <path
        style={{fill: outline}}
        d="M256,503.451c-43.3,0-78.528-35.226-78.528-78.518c0-43.297,35.231-78.525,78.528-78.525
		c43.3,0,78.525,35.228,78.525,78.525C334.525,468.225,299.3,503.451,256,503.451z M256,364.438c-33.359,0-60.5,27.141-60.5,60.495
		s27.141,60.49,60.5,60.49s60.498-27.136,60.498-60.49S289.359,364.438,256,364.438z"
      />
      <path
        style={{fill: outline}}
        d="M407.176,486.184l-17.838-2.632c1.034-6.991,4.224-13.34,9.229-18.353
		c6.182-6.17,14.38-9.559,23.094-9.559h23.631c8.732,0,16.929,3.395,23.091,9.559c4.995,4.995,8.177,11.331,9.213,18.317
		l-17.838,2.637c-0.461-3.133-1.889-5.972-4.129-8.215c-2.749-2.755-6.423-4.273-10.335-4.273h-23.631
		c-3.904,0-7.583,1.523-10.345,4.283C409.075,480.191,407.636,483.043,407.176,486.184z"
      />
      <path
        style={{fill: outline}}
        d="M433.472,453.797c-15.923,0-28.879-12.948-28.879-28.861c0-15.918,12.959-28.872,28.879-28.872
		s28.872,12.956,28.872,28.872C462.346,440.849,449.395,453.797,433.472,453.797z M433.472,414.089
		c-5.983,0-10.852,4.861-10.852,10.844c0,5.975,4.869,10.834,10.852,10.834c5.978,0,10.844-4.859,10.844-10.834
		C444.319,418.95,439.45,414.089,433.472,414.089z"
      />
      <path
        style={{fill: outline}}
        d="M433.472,503.451c-43.297,0-78.523-35.226-78.523-78.518c0-43.297,35.226-78.525,78.523-78.525
		c43.302,0,78.528,35.228,78.528,78.525C512,468.225,476.774,503.451,433.472,503.451z M433.472,364.438
		c-33.357,0-60.493,27.141-60.493,60.495s27.136,60.49,60.493,60.49s60.5-27.136,60.5-60.49S466.829,364.438,433.472,364.438z"
      />
    </g>
  </SVG>
))

export const Users = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: tone1}}
      d="M307.558,385.189c0-53.924,43.699-97.618,97.618-97.618c7.862,0,15.496,0.95,22.82,2.706
	c-2.849-56.952-33.382-106.565-78.385-135.777c-11.945,40.428-49.321,69.932-93.612,69.932c-44.298,0-81.674-29.504-93.609-69.932
	c-45.01,29.212-75.535,78.825-78.385,135.785c7.324-1.756,14.958-2.714,22.822-2.714c53.916,0,97.618,43.699,97.618,97.618
	c0,26.038-10.217,49.677-26.829,67.182c23.514,12.045,50.14,18.88,78.387,18.88c28.239,0,54.868-6.835,78.385-18.888
	C317.773,434.866,307.558,411.227,307.558,385.189z"
    />
    <g>
      <path
        style={{fill: outline}}
        d="M296.366,212.323c-0.781-5.164-3.131-9.828-6.797-13.496c-4.534-4.534-10.57-7.03-16.991-7.03
		h-33.19c-6.4,0-12.421,2.496-16.955,7.03c-3.671,3.671-6.019,8.335-6.799,13.496l-18.209-2.749
		c1.37-9.078,5.514-17.298,11.986-23.772c8.01-8.01,18.655-12.426,29.975-12.426h33.19c11.343,0,22.001,4.413,30.013,12.426
		c6.469,6.472,10.614,14.692,11.986,23.772L296.366,212.323z"
      />
      <path
        style={{fill: outline}}
        d="M256,163.903c-20.454,0-37.094-16.637-37.094-37.089c0-20.454,16.64-37.094,37.094-37.094
		c20.449,0,37.087,16.64,37.087,37.094C293.087,147.265,276.449,163.903,256,163.903z M256,108.136
		c-10.299,0-18.678,8.379-18.678,18.678c0,10.296,8.379,18.67,18.678,18.67c10.294,0,18.67-8.374,18.67-18.67
		C274.67,116.515,266.294,108.136,256,108.136z"
      />
      <path
        style={{fill: outline}}
        d="M364.823,470.696l-18.209-2.747c1.37-9.088,5.514-17.311,11.988-23.777
		c8.008-8.01,18.652-12.421,29.975-12.421h33.19c11.325,0,21.967,4.411,29.975,12.421c6.528,6.533,10.675,14.779,11.996,23.859
		l-18.225,2.655c-0.748-5.128-3.098-9.792-6.794-13.496c-4.526-4.526-10.547-7.017-16.95-7.017h-33.19
		c-6.403,0-12.426,2.493-16.952,7.02C367.954,460.86,365.601,465.532,364.823,470.696z"
      />
      <path
        style={{fill: outline}}
        d="M405.174,422.276c-20.454,0-37.097-16.637-37.097-37.087c0-20.454,16.643-37.094,37.097-37.094
		c20.449,0,37.087,16.64,37.087,37.094C442.263,405.638,425.626,422.276,405.174,422.276z M405.174,366.511
		c-10.299,0-18.678,8.376-18.678,18.678c0,10.294,8.376,18.67,18.678,18.67c10.294,0,18.67-8.374,18.67-18.67
		C423.844,374.888,415.47,366.511,405.174,366.511z"
      />
      <path
        style={{fill: outline}}
        d="M147.177,470.696c-0.781-5.166-3.133-9.836-6.799-13.504c-4.529-4.529-10.552-7.02-16.955-7.02
		H90.235c-6.403,0-12.426,2.493-16.955,7.02c-3.694,3.702-6.044,8.369-6.792,13.494l-18.225-2.655
		c1.321-9.08,5.468-17.329,11.991-23.857c8.01-8.013,18.657-12.424,29.98-12.424h33.188c11.325,0,21.97,4.411,29.978,12.421
		c6.472,6.467,10.614,14.689,11.986,23.777L147.177,470.696z"
      />
      <path
        style={{fill: outline}}
        d="M106.826,422.276c-20.452,0-37.089-16.637-37.089-37.087c0-20.454,16.637-37.094,37.089-37.094
		c20.454,0,37.094,16.64,37.094,37.094C143.921,405.638,127.281,422.276,106.826,422.276z M106.826,366.511
		c-10.296,0-18.67,8.376-18.67,18.678c0,10.294,8.374,18.67,18.67,18.67c10.299,0,18.678-8.374,18.678-18.67
		C125.504,374.888,117.125,366.511,106.826,366.511z"
      />
      <rect
        x="246.784"
        y="247.988"
        style={{fill: outline}}
        width="18.419"
        height="51.031"
      />

      <rect
        x="224.682"
        y="286.297"
        transform="matrix(-0.5001 -0.866 0.866 -0.5001 80.8291 670.3026)"
        style={{fill: outline}}
        width="18.42"
        height="51.048"
      />

      <rect
        x="252.583"
        y="302.578"
        transform="matrix(-0.866 -0.5 0.5 -0.866 363.0522 720.8577)"
        style={{fill: outline}}
        width="51.045"
        height="18.419"
      />
      <path
        style={{fill: outline}}
        d="M256,233.64c-58.903,0-106.826-47.923-106.826-106.826S197.097,19.987,256,19.987
		S362.826,67.91,362.826,126.813S314.903,233.64,256,233.64z M256,38.406c-48.748,0-88.407,39.66-88.407,88.407
		s39.66,88.41,88.407,88.41s88.407-39.662,88.407-88.41S304.747,38.406,256,38.406z"
      />
      <path
        style={{fill: outline}}
        d="M405.174,492.013c-58.903,0-106.826-47.923-106.826-106.826S346.273,278.36,405.174,278.36
		S512,326.286,512,385.189S464.077,492.013,405.174,492.013z M405.174,296.782c-48.747,0-88.41,39.66-88.41,88.407
		s39.662,88.407,88.41,88.407c48.745,0,88.407-39.66,88.407-88.407S453.921,296.782,405.174,296.782z"
      />
      <path
        style={{fill: outline}}
        d="M106.826,492.005C47.923,492.005,0,444.087,0,385.189c0-58.903,47.923-106.826,106.826-106.826
		s106.826,47.926,106.826,106.826C213.652,444.087,165.729,492.005,106.826,492.005z M106.826,296.782
		c-48.748,0-88.407,39.66-88.407,88.407c0,48.742,39.66,88.399,88.407,88.399s88.407-39.657,88.407-88.399
		C195.233,336.442,155.574,296.782,106.826,296.782z"
      />
    </g>
  </SVG>
))

export const CustomerSupport = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <g>
      <path
        style={{fill: outline}}
        d="M414.689,327.34H381.99v-19.236h13.466v-71.168c0-48.812-19.008-94.7-53.519-129.213
		c-34.514-34.514-80.404-53.519-129.213-53.519s-94.7,19.005-129.213,53.519c-34.516,34.517-53.522,80.402-53.522,129.216v71.168
		h13.463v19.236H10.752v-90.404c0-53.949,21.007-104.666,59.154-142.812c38.149-38.149,88.868-59.156,142.815-59.156
		s104.666,21.01,142.815,59.156c38.147,38.147,59.154,88.863,59.154,142.812L414.689,327.34L414.689,327.34z"
      />
      <path
        style={{fill: outline}}
        d="M317.45,132.209c-27.978-27.973-65.17-43.379-104.73-43.379s-76.751,15.404-104.73,43.377
		L94.39,118.605c31.611-31.606,73.631-49.011,118.331-49.011c44.698,0,86.723,17.405,118.328,49.014L317.45,132.209z"
      />
      <path
        style={{fill: outline}}
        d="M335.826,398.513c-9.761,0-18.941-3.802-25.843-10.703c-6.902-6.902-10.703-16.079-10.703-25.846
		v-88.479c0-9.766,3.799-18.947,10.703-25.843c6.904-6.902,16.082-10.703,25.843-10.703s18.939,3.802,25.846,10.708
		c6.899,6.894,10.703,16.072,10.703,25.841v25.006h-19.236v-25.006c0-4.626-1.8-8.973-5.066-12.237
		c-3.272-3.272-7.621-5.074-12.244-5.074s-8.97,1.805-12.242,5.071c-3.269,3.267-5.066,7.616-5.066,12.239v88.481
		c0,4.626,1.797,8.975,5.066,12.244c3.267,3.267,7.619,5.066,12.242,5.066s8.975-1.8,12.244-5.066
		c3.267-3.269,5.066-7.621,5.066-12.244v-25.006h19.236v25.006c0,9.764-3.802,18.941-10.703,25.846
		C354.767,394.711,345.59,398.513,335.826,398.513z"
      />
      <path
        style={{fill: outline}}
        d="M362.755,346.575c-15.91,0-28.854-12.943-28.854-28.854c0-15.908,12.943-28.851,28.854-28.851
		s28.854,12.943,28.854,28.851C391.608,333.632,378.665,346.575,362.755,346.575z M362.755,308.106
		c-5.304,0-9.618,4.314-9.618,9.615s4.314,9.618,9.618,9.618s9.618-4.316,9.618-9.618S368.059,308.106,362.755,308.106z"
      />
      <path
        style={{fill: outline}}
        d="M89.615,398.513c-9.764,0-18.941-3.802-25.843-10.703c-6.902-6.902-10.703-16.079-10.703-25.846
		v-25.006h19.236v25.006c0,4.626,1.8,8.975,5.066,12.245c3.269,3.267,7.619,5.066,12.244,5.066c4.626,0,8.975-1.8,12.244-5.066
		c3.269-3.269,5.066-7.621,5.066-12.245v-88.479c0-4.626-1.8-8.973-5.066-12.237c-3.272-3.272-7.619-5.074-12.244-5.074
		c-4.626,0-8.97,1.805-12.244,5.071c-3.269,3.267-5.066,7.616-5.066,12.239v25.006H53.069v-25.006
		c0-9.766,3.802-18.947,10.703-25.843c6.904-6.902,16.084-10.703,25.843-10.703s18.939,3.802,25.843,10.708
		c6.902,6.894,10.703,16.072,10.703,25.841v88.481c0,9.764-3.802,18.941-10.703,25.846
		C108.559,394.711,99.379,398.513,89.615,398.513z"
      />
      <path
        style={{fill: outline}}
        d="M62.687,346.575c-15.91,0-28.854-12.943-28.854-28.854c0-15.908,12.943-28.851,28.854-28.851
		s28.854,12.943,28.854,28.851C91.54,333.632,78.597,346.575,62.687,346.575z M62.687,308.106c-5.304,0-9.618,4.314-9.618,9.615
		s4.314,9.618,9.618,9.618s9.618-4.316,9.618-9.618S67.991,308.106,62.687,308.106z"
      />
      <path
        style={{fill: outline}}
        d="M251.192,512h-48.09c-15.91,0-28.854-12.943-28.854-28.854s12.941-28.854,28.854-28.854h48.087V512
		H251.192z M203.103,473.531c-5.304,0-9.618,4.314-9.618,9.618c0,5.304,4.314,9.618,9.618,9.618h28.854v-19.236L203.103,473.531
		L203.103,473.531z"
      />
      <path
        style={{fill: outline}}
        d="M241.574,492.764v-19.236c61.514,0,111.565-50.051,111.565-111.565h19.236
		C372.372,434.089,313.697,492.764,241.574,492.764z"
      />
    </g>
    <g>
      <rect x="212.71" style={{fill: tone1}} width="288.538" height="192.358" />
      <polygon
        style={{fill: tone1}}
        points="241.574,238.515 241.574,192.351 287.736,192.351 	"
      />
    </g>
    <g>
      <rect
        x="268.493"
        y="48.087"
        style={{fill: "#ffffff"}}
        width="67.323"
        height="19.236"
      />
      <rect
        x="268.493"
        y="86.559"
        style={{fill: "#ffffff"}}
        width="176.973"
        height="19.236"
      />
      <rect
        x="268.493"
        y="125.028"
        style={{fill: "#ffffff"}}
        width="136.576"
        height="19.236"
      />
    </g>
  </SVG>
))

export const Diskette = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <path
      style={{fill: tone1}}
      d="M411.036,173.678c-2.289,0-4.882,0.172-7.869,0.507l-23.383,2.701l-5.514-22.915
	c-13.14-54.871-61.783-93.207-118.272-93.207c-56.471,0-105.132,38.344-118.333,93.228l-5.517,22.93l-23.416-2.742
	c-2.916-0.328-5.458-0.499-7.741-0.499c-35.978,0-65.265,29.271-65.265,65.257c0,35.955,29.286,65.229,65.265,65.229H128v-64.087
	c0-4.593,1.764-9.293,5.368-12.836c3.535-3.663,8.251-5.43,12.897-5.43h27.461h45.678v-18.263h109.788v18.263h18.273L384,258.363
	v45.801h27.036c35.965,0,65.239-29.274,65.239-65.229C476.275,202.949,446.999,173.678,411.036,173.678z"
    />
    <path
      style={{fill: outline}}
      d="M411.036,339.878v-18.286c45.591,0,82.68-37.076,82.68-82.657c0-45.604-37.089-82.708-82.68-82.708
	c-2.926,0-6.144,0.21-9.843,0.64l-8.059,0.937l-1.889-7.89C376.228,87.158,320.609,43.328,255.997,43.328
	c-64.571,0-120.205,43.832-135.288,106.593l-1.897,7.892l-8.061-0.947c-3.651-0.43-6.845-0.64-9.764-0.64
	c-45.601,0-82.701,37.105-82.701,82.708c0,45.578,37.1,82.657,82.701,82.657v18.286C45.299,339.878,0,294.597,0,238.935
	c0-55.69,45.299-100.995,100.987-100.995c1.285,0,2.606,0.033,3.971,0.095c19.599-66.918,80.622-112.993,151.04-112.993
	c70.464,0,131.471,46.075,151.007,112.996c1.388-0.064,2.729-0.097,4.032-0.097C466.708,137.94,512,183.245,512,238.935
	C512,294.597,466.708,339.878,411.036,339.878z"
    />
    <path
      style={{fill: tone2}}
      d="M173.724,221.814v64c0,4.844,1.938,9.562,5.445,13.064c3.528,3.407,8.087,5.286,12.828,5.286h27.405
	v-82.35H173.724z"
    />
    <g>
      <rect
        x="146.253"
        y="212.685"
        style={{fill: outline}}
        width="73.139"
        height="18.286"
      />
      <path
        style={{fill: outline}}
        d="M365.734,486.958H146.263c-7.227,0-14.285-2.906-19.356-7.964
		c-5.207-5.289-8.051-12.175-8.051-19.443V240.079c0-7.24,2.949-14.292,8.1-19.354c4.951-5.133,11.863-8.054,19.308-8.054v18.286
		c-1.411,0-4.106,0.343-6.318,2.634c-1.843,1.815-2.803,4.119-2.803,6.487v219.471c0,2.473,0.947,4.736,2.737,6.554
		c1.618,1.613,3.963,2.57,6.385,2.57h219.471c2.468,0,4.756-0.947,6.615-2.737c1.564-1.62,2.506-3.907,2.506-6.385V262.154
		l-31.183-31.194H329.19v-18.286h22.06l41.889,41.907v204.972c0,7.217-2.801,14.085-7.882,19.333
		C379.779,484.168,372.925,486.958,365.734,486.958z"
      />
    </g>
    <rect
      x="182.861"
      y="368.077"
      style={{fill: tone2}}
      width="146.33"
      height="109.737"
    />
    <g>
      <path
        style={{fill: outline}}
        d="M338.335,486.958H173.724V358.935h164.611V486.958z M192.01,468.672h128.038v-91.451H192.01V468.672
		z"
      />
      <rect
        x="146.278"
        y="432.154"
        style={{fill: outline}}
        width="18.286"
        height="18.286"
      />
      <rect
        x="347.469"
        y="432.154"
        style={{fill: outline}}
        width="18.286"
        height="18.286"
      />
    </g>
    <g>
      <path
        style={{fill: outline}}
        d="M320,304.164H210.26V194.406h128.074v91.407c0,4.808-1.964,9.528-5.386,12.951
		C329.482,302.208,324.772,304.164,320,304.164z M228.547,285.878h91.364l0.125-73.185h-91.489V285.878z"
      />
      <rect
        x="283.392"
        y="231.014"
        style={{fill: outline}}
        width="18.289"
        height="36.529"
      />
    </g>
  </SVG>
))

export const CloudStorage = asTwoToneIcon((tone1, tone2, outline, props) => (
  <SVG viewBox="0 0 512 512" {...props}>
    <g>
      <path
        style={{fill: outline}}
        d="M360,364H152v-80h208V364z M168,348h176v-48H168V348z"
      />
      <path
        style={{fill: outline}}
        d="M360,460H152v-80h208V460z M168,444h176v-48H168V444z"
      />
      <path
        style={{fill: outline}}
        d="M360,268H152v-80h208V268z M168,252h176v-48H168V252z"
      />
      <path
        style={{fill: outline}}
        d="M344,300H168v-48h176V300z M184,284h144v-16H184V284z"
      />
      <path
        style={{fill: outline}}
        d="M344,396H168v-48h176V396z M184,380h144v-16H184V380z"
      />
      <polygon
        style={{fill: outline}}
        points="344,476 328,476 328,460 184,460 184,476 168,476 168,444 344,444 	"
      />
    </g>
    <g>
      <rect x="184" y="220" style={{fill: tone1}} width="80" height="16" />
      <rect x="312" y="220" style={{fill: tone1}} width="16" height="16" />
      <rect x="184" y="316" style={{fill: tone1}} width="80" height="16" />
      <rect x="312" y="316" style={{fill: tone1}} width="16" height="16" />
      <rect x="184" y="412" style={{fill: tone1}} width="80" height="16" />
      <rect x="312" y="412" style={{fill: tone1}} width="16" height="16" />
    </g>
    <g>
      <path
        style={{fill: tone2}}
        d="M192,148h-16c0-44.112,35.888-80,80-80v16C220.712,84,192,112.712,192,148z"
      />
      <path
        style={{fill: tone2}}
        d="M440,332h-64v-16h64c30.872,0,56-25.128,56-56c0-30.88-25.128-56-56-56h-2.4
		c-2.568,0-4.984-1.24-6.496-3.328c-1.504-2.088-1.912-4.768-1.096-7.208C431.296,189.624,432,184.84,432,180
		c0-30.88-25.128-56-56-56c-6.928,0-12.224,0.832-17.192,2.688c-2.064,0.784-4.368,0.664-6.376-0.336
		c-1.984-0.992-3.472-2.76-4.104-4.888C336.208,80.568,298.24,52,256,52s-80.208,28.568-92.328,69.472
		c-0.632,2.128-2.12,3.896-4.104,4.888c-1.984,0.992-4.304,1.112-6.376,0.336C148.232,124.832,142.928,124,136,124
		c-30.88,0-56,25.12-56,56c0,4.832,0.704,9.624,1.992,13.472c0.816,2.44,0.4,5.12-1.104,7.208S76.968,204,74.4,204H72
		c-30.88,0-56,25.12-56,56c0,30.872,25.12,56,56,56h64v16H72c-39.696,0-72-32.296-72-72c0-37.176,28.328-67.872,64.536-71.616
		C64.176,185.664,64,182.848,64,180c0-39.696,32.304-72,72-72c5.544,0,10.36,0.44,14.856,1.392C167.208,65.808,209.36,36,256,36
		s88.8,29.808,105.144,73.392C365.632,108.44,370.456,108,376,108c39.704,0,72,32.304,72,72c0,2.848-0.176,5.664-0.528,8.384
		C483.672,192.128,512,222.824,512,260C512,299.704,479.704,332,440,332z"
      />
    </g>
    <g>
      <rect x="376" y="380" style={{fill: outline}} width="16" height="16" />
      <rect x="376" y="412" style={{fill: outline}} width="16" height="16" />
      <rect x="376" y="444" style={{fill: outline}} width="16" height="16" />
      <rect x="120" y="444" style={{fill: outline}} width="16" height="16" />
      <rect x="120" y="412" style={{fill: outline}} width="16" height="16" />
      <rect x="120" y="380" style={{fill: outline}} width="16" height="16" />
    </g>
  </SVG>
))
