import React, {useState} from "react"
import {useEffect, useMemo} from "react"
import {useDispatch} from "react-redux"
import {
  messageTypes,
  sendMessage,
  useMessaging
} from "../messaging/messaging.utils"
import {addNotification} from "../notifications/notifications.store"
import {updateColorMode, updateAppConfiguration} from "./embedded-app.store"
import * as R from "ramda"

/**
 * Update location to the parent frame when user navigates within the app
 */
export const useSyncLocationWithParentFrame = location => {
  const {pathname, search, hash, key} = location

  useEffect(() => {
    if (key && window.parent) {
      sendMessage(window.parent, messageTypes.UPDATE_LOCATION, {
        pathname,
        search,
        hash
      })
    }
  }, [pathname, search, hash])
}

/**
 * Send messages to a frame when frame is receiving them
 */
export const useSyncFrameParameters = (iframe, appConfig, colorMode) => {
  const [latestSyncRequested, setLatestSyncRequested] = useState(null)
  useMessaging(type => {
    type === messageTypes.READY && setLatestSyncRequested(+new Date())
  })
  useEffect(() => {
    iframe &&
      latestSyncRequested &&
      sendMessage(
        iframe.contentWindow,
        messageTypes.UPDATE_APP_CONFIG,
        appConfig
      )
  }, [latestSyncRequested, appConfig, iframe])
  useEffect(() => {
    iframe &&
      latestSyncRequested &&
      sendMessage(
        iframe.contentWindow,
        messageTypes.UPDATE_COLOR_MODE,
        colorMode
      )
  }, [latestSyncRequested, colorMode, iframe])
}

const messageHandlers = {
  [messageTypes.UPDATE_COLOR_MODE]: updateColorMode,
  [messageTypes.UPDATE_APP_CONFIG]: updateAppConfiguration
}

/**
 * Receive messages from the parent frame
 */
export const useFrameMessaging = () => {
  const dispatch = useDispatch()
  const onReceiveMessage = (type, payload) => {
    const handleMessage = messageHandlers[type]
    dispatch(handleMessage(payload))
  }
  const onStartListening = () => sendMessage(window.parent, messageTypes.READY)
  useMessaging(onReceiveMessage, onStartListening)
}

/**
 * Update location in history api when frame sends UPDATE_LOCATION message
 */
export const useSyncFrameLocation = (app, history, location) => {
  useMessaging((type, payload) => {
    if (type === messageTypes.UPDATE_LOCATION) {
      const {pathname, search, hash} = payload
      history.replace({hash: pathname + search + hash})
    }
  })

  useEffect(() => {
    const path = location.hash.replace("#", "")
    if (!path.startsWith(app.app_src) || path === app.app_src + "/") {
      history.replace({hash: null})
    }
  }, [location.hash])
}

export const withAppendingSlash = R.unless(
  R.endsWith("/"),
  R.pipe(R.append("/"), R.join(""))
)

/**
 * Memoize app location for a frame
 */
export const useFrameSrc = (app, location) => {
  const src = useMemo(() => {
    const path = location.hash.replace("#", "")
    return withAppendingSlash(path.startsWith(app.app_src) ? path : app.app_src)
  }, [app.app_src])

  return src
}

export const useReceiveFrameNotifications = () => {
  const dispatch = useDispatch()

  useMessaging((type, payload) => {
    if (type === messageTypes.NOTIFY_USER) {
      const {message, notificationProps} = payload
      dispatch(addNotification(message, notificationProps))
    }
  })
}
