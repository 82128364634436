import styled from "styled-components"
import {
  background,
  compose,
  layout,
  position,
  space,
  typography
} from "styled-system"

export const Clickable = styled.button`
  display: inline-block;
  width: auto;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;

  font-size: 1rem;

  cursor: pointer;

  ${compose(layout, space, background, position, typography)}
`
