import React from "react"
import PropTypes from "prop-types"
import styled, {keyframes} from "styled-components"
import {
  compose,
  typography,
  space,
  layout,
  position,
  width,
  color
} from "styled-system"
import css from "@styled-system/css"
import {Clickable} from "../input/clickable"

const appearUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const StyledToast = styled.div`
  position: relative;

  padding: 1em 1em 1em 3.5em;

  animation: ${appearUp} 0.5s;
  animation-fill-mode: forwards;

  ${css({
    color: "altText",
    bg: "info",
    fontSize: 1,
    borderRadius: "panel",
    boxShadow: "heavy"
  })}

  & > svg {
    position: absolute;
    left: 0.75em;
    top: 50%;

    transform: translateY(-50%);

    font-size: 1.5em;

    ${css({
      color: "bg"
    })}
  }

  ${compose(typography, space, layout, color)}
`

export const Toaster = styled.section`
  position: fixed;
  top: 1em;

  ${css({
    right: ["auto", "1em"],
    width: ["calc(100% - 2em)", "toasterMax"],
    zIndex: "toast"
  })}

  & > * + * {
    margin-top: 1em;
  }

  ${compose(position, width)}
`

export const Toast = ({icon, onClose, children, ...optional}) => {
  if (onClose) {
    return (
      <Clickable textAlign="left" width={1 / 1} onClick={onClose}>
        <StyledToast {...optional}>
          {icon}
          {children}
        </StyledToast>
      </Clickable>
    )
  }

  return (
    <StyledToast {...optional}>
      {icon}
      {children}
    </StyledToast>
  )
}

Toast.defaultProps = {
  /** Icon displayed on the toast */
  icon: PropTypes.element.isRequired,
  /** Callback triggered when user activates the toast */
  onClose: PropTypes.func
}
