import PropTypes from "prop-types"
import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {AuthenticatedHeader} from "../../components/header/authenticated-header"
import {ViewWrapper} from "../../components/view-wrapper/view-wrapper"
import {useLoadUserSession} from "@frontai/atlas/features/user-session/user-session.hooks"
import {
  getUser,
  getApps,
  getProject,
  updateProject
} from "@frontai/atlas/features/user-session/user-session.store"
import {Spinner, AbsoluteSpinnerWrapper} from "@frontai/design-system"
import {ProjectSelection} from "../project-selection/project-selection"
import {AppSelection} from "../app-selection/app-selection"
import {Route, Switch} from "react-router-dom"
import {AtlasApp} from "../atlas-app/atlas-app"

export const LandingPage = ({toggleColorMode}) => {
  useLoadUserSession()
  const user = useSelector(getUser)
  const apps = useSelector(getApps)
  const project = useSelector(getProject)
  const dispatch = useDispatch()

  if (!user || !apps) {
    return (
      <AbsoluteSpinnerWrapper>
        <Spinner>Loading</Spinner>
      </AbsoluteSpinnerWrapper>
    )
  }

  const onClickChangeProject =
    user.projects.length > 1 &&
    (() => {
      dispatch(updateProject(undefined))
      window.location.href = "/"
    })

  return (
    <ViewWrapper
      header={
        <AuthenticatedHeader
          toggleColorMode={toggleColorMode}
          user={user}
          apps={apps}
          project={project}
          onClickChangeProject={onClickChangeProject}
        />
      }
    >
      {!project && <ProjectSelection projects={user.projects} />}

      <Switch>
        <Route
          path="/"
          exact
          render={props => <AppSelection apps={apps} {...props} />}
        />
        <Route
          path="/:id?"
          render={props => <AtlasApp user={user} apps={apps} {...props} />}
        />
        <Route></Route>
      </Switch>
    </ViewWrapper>
  )
}

LandingPage.propTypes = {
  toggleColorMode: PropTypes.func.isRequired
}
