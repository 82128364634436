import React from "react"
import styled from "styled-components"
import {color, compose, space, typography} from "styled-system"

const SVG = styled.svg`
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  ${compose(typography, space, color)}
`

export const Apps = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 341.333 341.333" {...props}>
    <g>
      <rect x="128" y="128" width="85.333" height="85.333" />
      <rect x="0" y="0" width="85.333" height="85.333" />
      <rect x="128" y="256" width="85.333" height="85.333" />
      <rect x="0" y="128" width="85.333" height="85.333" />
      <rect x="0" y="256" width="85.333" height="85.333" />
      <rect x="256" y="0" width="85.333" height="85.333" />
      <rect x="128" y="0" width="85.333" height="85.333" />
      <rect x="256" y="128" width="85.333" height="85.333" />
      <rect x="256" y="256" width="85.333" height="85.333" />
    </g>
  </SVG>
)

export const List = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 394.667 394.667" {...props}>
    <g>
      <path d="M32,37.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,37.333,32,37.333z" />
      <path d="M32,165.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,165.333,32,165.333z" />
      <path d="M32,293.333c-17.813,0-32,14.4-32,32c0,17.6,14.4,32,32,32c17.6,0,32-14.4,32-32C64,307.733,49.813,293.333,32,293.333z" />
      <rect x="96" y="304" width="298.667" height="42.667" />
      <rect x="96" y="48" width="298.667" height="42.667" />
      <rect x="96" y="176" width="298.667" height="42.667" />
    </g>
  </SVG>
)

export const Cards = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 362.667 362.667" {...props}>
    <g>
      <rect x="0" y="42.667" width="106.667" height="128" />
      <rect x="0" y="192" width="106.667" height="128" />
      <rect x="128" y="192" width="106.667" height="128" />
      <rect x="256" y="192" width="106.667" height="128" />
      <rect x="256" y="42.667" width="106.667" height="128" />
      <rect x="128" y="42.667" width="106.667" height="128" />
    </g>
  </SVG>
)

export const Menu = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 384 384" {...props}>
    <g>
      <rect x="0" y="277.333" width="384" height="42.667" />
      <rect x="0" y="170.667" width="384" height="42.667" />
      <rect x="0" y="64" width="384" height="42.667" />
    </g>
  </SVG>
)

export const Cross = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 298.667 298.667" {...props}>
    <g>
      <polygon
        points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667
			149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333 		"
      />
    </g>
  </SVG>
)

export const Info = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 426.667 426.667" {...props}>
    <g>
      <path
        d="M213.227,0C95.36,0,0,95.467,0,213.333s95.36,213.333,213.227,213.333s213.44-95.467,213.44-213.333S331.093,0,213.227,0
				z M213.333,384c-94.293,0-170.667-76.373-170.667-170.667S119.04,42.667,213.333,42.667S384,119.04,384,213.333
				S307.627,384,213.333,384z"
      />
      <rect x="192" y="277.333" width="42.667" height="42.667" />
      <rect x="192" y="106.667" width="42.667" height="128" />
    </g>
  </SVG>
)

export const Logout = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 384 384" {...props}>
    <g>
      <path
        d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667V128h42.667V42.667h298.667v298.667H42.667V256H0v85.333
				C0,364.907,19.093,384,42.667,384h298.667C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z"
      />
      <polygon
        points="151.147,268.48 181.333,298.667 288,192 181.333,85.333 151.147,115.52 206.293,170.667 0,170.667 0,213.333
				206.293,213.333 			"
      />
    </g>
  </SVG>
)
export const Language = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 469.333 469.333" {...props}>
    <g>
      <path
        d="M253.227,300.267L253.227,300.267L199.04,246.72l0.64-0.64c37.12-41.387,63.573-88.96,79.147-139.307h62.507V64H192
				V21.333h-42.667V64H0v42.453h238.293c-14.4,41.173-36.907,80.213-67.627,114.347c-19.84-22.08-36.267-46.08-49.28-71.467H78.72
				c15.573,34.773,36.907,67.627,63.573,97.28l-108.48,107.2L64,384l106.667-106.667l66.347,66.347L253.227,300.267z"
      />
      <path
        d="M373.333,192h-42.667l-96,256h42.667l24-64h101.333l24,64h42.667L373.333,192z M317.333,341.333L352,248.853
				l34.667,92.48H317.333z"
      />
    </g>
  </SVG>
)
export const LightSwitch = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 512 512" {...props}>
    <g>
      <path d="M179.2,486.4c0,14.08,11.52,25.6,25.6,25.6h102.4c14.08,0,25.6-11.52,25.6-25.6v-25.6H179.2V486.4z" />
      <path
        d="M256,0C157.056,0,76.8,80.256,76.8,179.2c0,60.928,30.464,114.56,76.8,146.944V384c0,14.08,11.52,25.6,25.6,25.6h153.6
				c14.08,0,25.6-11.52,25.6-25.6v-57.856c46.336-32.384,76.8-86.016,76.8-146.944C435.2,80.256,354.944,0,256,0z M329.088,284.16
				L307.2,299.392V358.4H204.8v-58.88l-21.888-15.232C148.48,260.224,128,221.056,128,179.328c0-70.528,57.472-128,128-128
				s128,57.472,128,128C384,220.928,363.52,260.096,329.088,284.16z"
      />
    </g>
  </SVG>
)
export const User = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 341.333 341.333" {...props}>
    <g>
      <path
        d="M170.667,192C113.813,192,0,220.48,0,277.333v64h341.333v-64C341.333,220.48,227.52,192,170.667,192z M300.8,300.8
				H40.533v-23.467c0-13.76,66.667-44.8,130.133-44.8s130.133,31.04,130.133,44.8V300.8z"
      />
      <path
        d="M170.667,170.667c47.147,0,85.333-38.293,85.333-85.333C256,38.187,217.813,0,170.667,0S85.333,38.187,85.333,85.333
				C85.333,132.373,123.52,170.667,170.667,170.667z M170.667,40.533c24.747,0,44.8,20.053,44.8,44.8
				c0,24.747-20.053,44.8-44.8,44.8s-44.8-20.053-44.8-44.8C125.867,60.587,145.92,40.533,170.667,40.533z"
      />
    </g>
  </SVG>
)
export const Download = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 362.667 362.667" {...props}>
    <g>
      <polygon points="330.667,128 245.333,128 245.333,0 117.333,0 117.333,128 32,128 181.333,277.333 			" />
      <rect x="32" y="320" width="298.667" height="42.667" />
    </g>
  </SVG>
)

export const ColorMode = props => (
  <SVG
    aria-hidden
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 412.16 412.16"
    {...props}
  >
    <g>
      <path
        d="M326.72,120.747L206.08,0L85.44,120.747c-66.667,66.667-66.667,174.72,0,241.387c33.28,33.28,77.013,50.027,120.64,50.027
			s87.36-16.64,120.64-50.027C393.387,295.467,393.387,187.413,326.72,120.747z M206.08,369.387
			c-34.24,0-66.347-13.333-90.453-37.547c-24.213-24.107-37.547-56.213-37.547-90.453s13.333-66.347,37.547-90.56l90.453-90.453
			V369.387z"
      />
    </g>
  </SVG>
)

export const Upload = props => (
  <SVG
    aria-hidden
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 362.667 362.667"
    {...props}
  >
    <g>
      <polygon points="117.333,277.333 245.333,277.333 245.333,149.333 330.667,149.333 181.333,0 32,149.333 117.333,149.333 			" />
      <rect x="32" y="320" width="298.667" height="42.667" />
    </g>
  </SVG>
)

export const Edit = props => (
  <SVG
    aria-hidden
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 383.947 383.947"
    {...props}
  >
    <g>
      <polygon points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893 			" />
      <path
        d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04
				C386.027,77.92,386.027,64.373,377.707,56.053z"
      />
    </g>
  </SVG>
)
export const Help = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 426.667 426.667" {...props}>
    <g>
      <rect x="192" y="298.667" width="42.667" height="42.667" />
      <path
        d="M213.333,85.333c-47.147,0-85.333,38.187-85.333,85.333h42.667c0-23.467,19.2-42.667,42.667-42.667
				S256,147.2,256,170.667c0,42.667-64,37.333-64,106.667h42.667c0-48,64-53.333,64-106.667
				C298.667,123.52,260.48,85.333,213.333,85.333z"
      />
      <path
        d="M213.333,0C95.573,0,0,95.573,0,213.333s95.573,213.333,213.333,213.333s213.333-95.573,213.333-213.333
				S331.093,0,213.333,0z M213.333,384c-94.08,0-170.667-76.587-170.667-170.667S119.253,42.667,213.333,42.667
				S384,119.253,384,213.333S307.413,384,213.333,384z"
      />
    </g>
  </SVG>
)

export const Settings = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 426.667 426.667" {...props}>
    <g>
      <path
        d="M416.8,269.44l-45.013-35.307c0.853-6.827,1.493-13.76,1.493-20.8s-0.64-13.973-1.493-20.8l45.12-35.307
			c4.053-3.2,5.227-8.96,2.56-13.653L376.8,69.653c-2.667-4.587-8.213-6.507-13.013-4.587l-53.12,21.44
			c-10.987-8.427-23.04-15.573-36.053-21.013l-8-56.533C265.653,3.947,261.28,0,255.947,0h-85.333c-5.333,0-9.707,3.947-10.56,8.96
			l-8,56.533c-13.013,5.44-25.067,12.48-36.053,21.013l-53.12-21.44c-4.8-1.813-10.347,0-13.013,4.587L7.2,143.573
			c-2.667,4.587-1.493,10.347,2.56,13.653l45.013,35.307c-0.853,6.827-1.493,13.76-1.493,20.8s0.64,13.973,1.493,20.8L9.76,269.44
			c-4.053,3.2-5.227,8.96-2.56,13.653l42.667,73.92c2.667,4.587,8.213,6.507,13.013,4.587L116,340.16
			c10.987,8.427,23.04,15.573,36.053,21.013l8,56.533c0.853,5.013,5.227,8.96,10.56,8.96h85.333c5.333,0,9.707-3.947,10.56-8.96
			l8-56.533c13.013-5.44,25.067-12.48,36.053-21.013l53.12,21.44c4.8,1.813,10.347,0,13.013-4.587l42.667-73.92
			C422.027,278.507,420.853,272.747,416.8,269.44z M213.28,288c-41.28,0-74.667-33.387-74.667-74.667S172,138.667,213.28,138.667
			s74.667,33.387,74.667,74.667S254.56,288,213.28,288z"
      />
    </g>
  </SVG>
)

export const Refresh = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 469.333 469.333" {...props}>
    <g>
      <path
        d="M378.88,143.787l-31.147,31.147c9.493,17.813,14.933,38.08,14.933,59.733c0,70.72-57.28,128-128,128v-64L149.333,384
				l85.333,85.333v-64c94.293,0,170.667-76.373,170.667-170.667C405.333,201.173,395.52,170.133,378.88,143.787z"
      />
      <path
        d="M234.667,106.667v64L320,85.333L234.667,0v64C140.373,64,64,140.373,64,234.667c0,33.493,9.813,64.533,26.453,90.88
				L121.6,294.4c-9.493-17.813-14.933-38.08-14.933-59.733C106.667,163.947,163.947,106.667,234.667,106.667z"
      />
    </g>
  </SVG>
)

export const Filter = props => (
  <SVG
    aria-hidden
    x="0px"
    y="0px"
    width="459px"
    height="459px"
    viewBox="0 0 459 459"
    {...props}
  >
    <g id="filter">
      <path d="M178.5,382.5h102v-51h-102V382.5z M0,76.5v51h459v-51H0z M76.5,255h306v-51h-306V255z" />
    </g>
  </SVG>
)

export const Search = props => (
  <SVG
    aria-hidden
    x="0px"
    y="0px"
    width="446.25px"
    height="446.25px"
    viewBox="0 0 446.25 446.25"
    {...props}
  >
    <g id="search">
      <path
        d="M318.75,280.5h-20.4l-7.649-7.65c25.5-28.05,40.8-66.3,40.8-107.1C331.5,73.95,257.55,0,165.75,0S0,73.95,0,165.75
			S73.95,331.5,165.75,331.5c40.8,0,79.05-15.3,107.1-40.8l7.65,7.649v20.4L408,446.25L446.25,408L318.75,280.5z M165.75,280.5
			C102,280.5,51,229.5,51,165.75S102,51,165.75,51S280.5,102,280.5,165.75S229.5,280.5,165.75,280.5z"
      />
    </g>
  </SVG>
)

export const Sort = props => (
  <SVG
    aria-hidden
    x="0px"
    y="0px"
    width="459px"
    height="459px"
    viewBox="0 0 459 459"
    {...props}
  >
    <g id="unfold-more">
      <path
        d="M229.5,71.4l81.6,81.6l35.7-35.7L229.5,0L112.2,117.3l35.7,35.7L229.5,71.4z M229.5,387.6L147.9,306l-35.7,35.7L229.5,459
			l117.3-117.3L311.1,306L229.5,387.6z"
      />
    </g>
  </SVG>
)

export const CaretDown = props => (
  <SVG
    aria-hidden
    x="0px"
    y="0px"
    width="292.362px"
    height="292.362px"
    viewBox="0 0 292.362 292.362"
    {...props}
  >
    <g>
      <path
        d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
		C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
		s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
      />
    </g>
  </SVG>
)

export const Alert = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 469.333 469.333" {...props}>
    <g>
      <path
        d="M234.667,32L0,437.333h469.333L234.667,32z M256,373.333h-42.667v-42.667H256V373.333z M213.333,288v-85.333H256V288
			H213.333z"
      />
    </g>
  </SVG>
)

export const More = props => (
  <SVG aria-hidden x="0px" y="0px" viewBox="0 0 341.333 341.333" {...props}>
    <g>
      <path
        d="M170.667,85.333c23.573,0,42.667-19.093,42.667-42.667C213.333,19.093,194.24,0,170.667,0S128,19.093,128,42.667
				C128,66.24,147.093,85.333,170.667,85.333z"
      />
      <path
        d="M170.667,128C147.093,128,128,147.093,128,170.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667
				S194.24,128,170.667,128z"
      />
      <path
        d="M170.667,256C147.093,256,128,275.093,128,298.667c0,23.573,19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667
				C213.333,275.093,194.24,256,170.667,256z"
      />
    </g>
  </SVG>
)

export const File = props => (
  <SVG x="0px" y="0px" viewBox="0 0 426.667 426.667" {...props}>
    <path
      d="M256,0H85.333C61.76,0,42.88,19.093,42.88,42.667L42.667,384c0,23.573,18.88,42.667,42.453,42.667h256.213
			C364.907,426.667,384,407.573,384,384V128L256,0z M298.667,341.333H128v-42.667h170.667V341.333z M298.667,256H128v-42.667
			h170.667V256z M234.667,149.333V32L352,149.333H234.667z"
    />
  </SVG>
)

export const Plus = props => (
  <SVG x="0px" y="0px" viewBox="0 0 298.667 298.667" {...props}>
    <polygon
      points="170.667,128 170.667,0 128,0 128,128 0,128 0,170.667 128,170.667 128,298.667 170.667,298.667 170.667,170.667
			298.667,170.667 298.667,128 		"
    />
  </SVG>
)

export const Minus = props => (
  <SVG x="0px" y="0px" viewBox="0 0 298.667 298.667" {...props}>
    <rect y="128" width="298.667" height="42.667" />
  </SVG>
)
