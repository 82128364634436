import * as R from "ramda"

export const SESSION_STATUS = {
  live: Symbol("live"),
  expired: Symbol("expired"),
  aboutToExpire: Symbol("about to expire")
}

const isOrganizationProperty = (_, key) => R.startsWith("org_", key)
const isProjectProperty = (_, key) => R.startsWith("project_", key)

const pickOrganizationPropsFromProjects = R.pipe(
  R.head,
  R.pickBy(isOrganizationProperty)
)

const pickProjectPropsFromProjects = R.pipe(
  R.map(R.pickBy(isProjectProperty)),
  R.objOf("projects")
)

export const groupProjectsByOrganization = R.pipe(
  R.groupBy(R.prop("org_id")),
  R.values,
  R.map(
    R.converge(R.merge, [
      pickOrganizationPropsFromProjects,
      pickProjectPropsFromProjects
    ])
  )
)
