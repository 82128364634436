import React from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import styled from "styled-components"
import {compose, space, layout} from "styled-system"
import css from "@styled-system/css"
import {preventPropForwarding} from "../core/utils"

const StyledAppLink = styled.a.withConfig({
  shouldForwardProp: preventPropForwarding("small")
})`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 8em;
  height: 8em;

  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;

  transition: all 0.15s;

  ${css({
    color: "text",
    borderRadius: "panel"
  })}

  ${p =>
    // @ts-ignore: TS doesn't understand props without typings
    p.small &&
    css({
      width: "6em",
      height: "6em"
    })}

  &:hover {
    transform: scale(1.0625);
    ${css({boxShadow: "medium"})}
  }

  ${compose(space, layout)}

  & > svg {
    font-size: ${p =>
      // @ts-ignore: TS doesn't understand props without typings
      p.small ? "3em" : "4.5em"};
  }

  & > div {
    padding: 0 0.5em;

    ${css({
      fontSize: 0,
      marginTop: 3
    })}

    ${p =>
      // @ts-ignore: TS doesn't understand props without typings
      p.small && css({fontSize: "0.5833rem", marginTop: 2})}
  }
`

export const AppLink = ({icon, children, ...optional}) => {
  const props = {
    ...optional,
    as: optional.href ? "a" : Link
  }

  return (
    // @ts-ignore: TS doesn't understand as-prop without typings
    <StyledAppLink {...props}>
      {icon}
      <div>{children}</div>
    </StyledAppLink>
  )
}

AppLink.propTypes = {
  /** App icon */
  icon: PropTypes.element.isRequired,
  small: PropTypes.bool,
  /** Link target for internal links */
  to: PropTypes.string,
  /** Link target for external links */
  href: PropTypes.string
}

AppLink.defaultProps = {
  small: false
}
