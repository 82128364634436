import {
  endpoints,
  SESSION_STATUS_POLL_INTERVAL_MS
} from "@frontai/atlas/settings"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import "regenerator-runtime"
import {addNotification} from "../notifications/notifications.store"
import {notificationTypes} from "../notifications/notifications.utils"
import {
  expireSession,
  getSessionStatus,
  updateApps,
  updateSessionStatus,
  updateUser
} from "./user-session.store"
import {SESSION_STATUS} from "./user-session.utils"

export const useUserSessionLoaders = () => {
  const dispatch = useDispatch()

  const loadUser = async () => {
    const response = await fetch(endpoints.user.info, {method: "POST"})
    if (response.status >= 400) {
      dispatch(updateUser(new Error("Error while loading user")))
      return
    }
    const {payload} = await response.json()
    dispatch(updateUser(payload))
  }

  const loadApps = async () => {
    const response = await fetch(endpoints.user.app, {method: "POST"})
    if (response.status >= 400) {
      dispatch(updateApps(new Error("Error while loading apps")))
      return
    }
    const {payload} = await response.json()
    dispatch(updateApps(payload))
  }

  return [loadUser, loadApps]
}

export const useLoadUserSession = () => {
  const [loadUser, loadApps] = useUserSessionLoaders()

  useEffect(() => {
    loadUser()
    loadApps()
  }, [])
}

export const usePollSessionStatus = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const pollCheckEndpoint = async () => {
      try {
        const response = await fetch(endpoints.idp.check, {method: "POST"})
        if (response.status >= 400) {
          dispatch(expireSession())
          return
        }
        dispatch(updateSessionStatus(response.json()))
      } catch (err) {
        dispatch(expireSession())
      }
      setTimeout(() => pollCheckEndpoint(), SESSION_STATUS_POLL_INTERVAL_MS)
    }
    pollCheckEndpoint()
  }, [])
}

export const usePromptSessionRefresh = () => {
  const sessionStatus = useSelector(getSessionStatus)

  useEffect(() => {
    if (sessionStatus === SESSION_STATUS.aboutToExpire) {
      dispatch(
        addNotification(
          "Your session is about to expire, click here to continue using Atlas.",
          {
            type: notificationTypes.ALERT
          },
          async () => {
            const response = await fetch(endpoints.idp.continue, {
              method: "POST"
            })

            if (response.status === 200) {
              dispatch(
                addNotification("Session refreshed successfully!", {
                  type: notificationTypes.SUCCESS
                })
              )
            }
          }
        )
      )
    }
  }, [sessionStatus])
}
