import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {color, compose, space, typography, variant} from "styled-system"

const StyledHeading = styled.h1`
  font-weight: bold;
  text-transform: uppercase;

  ${variant({
    variants: {
      1: {
        fontSize: 5,
        mt: "0.67em",
        mb: "0.67em"
      },
      2: {
        fontSize: 4,
        mt: "0.83em",
        mb: "0.83em"
      },
      3: {
        fontSize: 3,
        mt: "1em",
        mb: "1em"
      },
      4: {
        fontSize: 2,
        mt: "1.33em",
        mb: "1.33em"
      },
      5: {
        fontSize: 1,
        mt: "1.67em",
        mb: "1.67em"
      },
      6: {
        fontSize: 0,
        mt: "2.33em",
        mb: "2.33em"
      }
    }
  })}

  ${compose(typography, space, color)}
`

export const Heading = ({children, level, ...optional}) => {
  const {variant, ...props} = optional
  return (
    <StyledHeading
      // @ts-ignore
      as={"h" + level}
      variant={variant || level}
      {...props}
    >
      {children}
    </StyledHeading>
  )
}

Heading.propTypes = {
  /** Heading level (1-6) */
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Heading style (1-6) */
  variant: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ])
}
