/**
 * This store contains basic configuration for the atlas portal and any apps, it includes
 * - base configuration
 * - color theme
 * - initial query parameters from the URL
 */

import {createAction, handleActions, combineActions} from "redux-actions"
import {createSelector} from "reselect"

/**
 * Actions
 */

export const updateBaseConfiguration = createAction(
  "UPDATE_BASE_CONFIGURATION",
  baseConfig => ({baseConfig})
)
export const updateColorMode = createAction("UPDATE_COLOR_MODE", mode => ({
  mode
}))
export const updateQueryString = createAction(
  "UPDATE_QUERY_STRING",
  queryString => ({queryString})
)

/**
 * Reducer
 */

export const appConfigurationReducer = handleActions(
  {
    [combineActions(
      updateBaseConfiguration,
      updateColorMode,
      updateQueryString
    )]: (state, {payload, error}) => {
      return {...state, ...payload, hasError: error}
    }
  },
  {}
)

/**
 * Selectors
 */

export const getAppConfigurationState = state => state.appConfigurationReducer
export const getBaseConfig = createSelector(
  getAppConfigurationState,
  config => config.baseConfig
)
export const getColorMode = createSelector(
  getAppConfigurationState,
  config => config.mode
)
export const getQueryString = createSelector(
  getAppConfigurationState,
  config => config.queryString
)
