/**
 * This store contains state that should be available for all components of the
 * atlas portal (the root app of the atlas-client), which includes
 * - user info
 * - apps and projects available to the user
 * - currently selected project
 * - currently selected app (?)
 */

import {AUTO_SELECT_PROJECT} from "@frontai/atlas/settings"
import {SESSION_STATUS} from "./user-session.utils"
import {combineActions, createAction, handleActions} from "redux-actions"
import {createSelector} from "reselect"

/**
 * Actions
 */

export const expireSession = createAction("EXPIRE_SESSION", () => ({
  sessionStatus: SESSION_STATUS.expired
}))
export const updateSessionStatus = createAction(
  "UPDATE_SESSION_STATUS",
  payload => ({
    sessionStatus:
      payload.body === "expiry_soon"
        ? SESSION_STATUS.aboutToExpire
        : SESSION_STATUS.live
  })
)
export const updateUser = createAction("UPDATE_USER", payload => {
  if (payload.body.projects.length === 1 && AUTO_SELECT_PROJECT) {
    // Select project automatically if there is only one option
    return {
      user: payload.body,
      project: payload.body.projects[0]
    }
  }

  return {
    user: payload.body
  }
})
export const updateApps = createAction("UPDATE_APPS", payload => ({
  apps: payload.body
}))
export const updateProject = createAction("UPDATE_PROJECT", project => ({
  project
}))

/**
 * Reducer
 */

export const userSessionReducer = handleActions(
  {
    [combineActions(
      expireSession,
      updateSessionStatus,
      updateUser,
      updateApps,
      updateProject
    )]: (state, {payload, error}) => {
      return {...state, ...payload, hasError: error}
    }
  },
  {}
)

/**
 * Selectors
 */

export const getUserSessionState = state => state.userSessionReducer
export const getSessionStatus = createSelector(
  getUserSessionState,
  config => config.sessionStatus
)
export const getUser = createSelector(
  getUserSessionState,
  config => config.user
)
export const getApps = createSelector(
  getUserSessionState,
  config => config.apps
)
export const getProject = createSelector(
  getUserSessionState,
  config => config.project
)
