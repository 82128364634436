import {useState, useEffect} from "react"
import {getDomainFromEmail} from "./login.utils"

export const useDomain = email => {
  const [domain, setDomain] = useState("")

  useEffect(() => {
    setDomain(getDomainFromEmail(email))
  }, [email])

  return domain
}
