import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const HEADER_HEIGHT = "3rem"

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${p => p.theme.zIndices.menu};
  width: 100%;
  height: ${HEADER_HEIGHT};
`

const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: calc(100vh - ${HEADER_HEIGHT});
  padding-top: ${HEADER_HEIGHT};

  // Set the z-index context for all elements under main container
  transform: translateZ(0);
`

export const ViewWrapper = ({header, children}) => (
  <>
    <HeaderWrapper>{header}</HeaderWrapper>
    <Main>{children}</Main>
  </>
)

ViewWrapper.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
}
