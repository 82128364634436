import css from "@styled-system/css"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {
  border,
  compose,
  layout,
  space,
  typography,
  variant
} from "styled-system"
import {Clickable} from "./clickable"
import {preventPropForwarding} from "../core/utils"

const basicVariants = variant({
  variants: {
    primary: {
      color: "altText",
      bg: "brand"
    },
    secondary: {
      color: "text",
      bg: "bg",
      border: "1px solid",
      borderColor: "brand"
    }
  }
})

const dangerVariants = ({variant, danger}) => {
  if (!danger) {
    return
  }

  const colorProperty = variant === "primary" ? "bg" : "borderColor"
  return css({[colorProperty]: "danger"})
}

const disabledVariants = ({variant}) => {
  if (variant === "primary") {
    return css({bg: "disabled"})
  }

  return css({borderColor: "disabled", color: "disabled"})
}

const sizeVariants = ({small, large}) =>
  css({fontSize: small ? 0 : large ? 2 : 1})

export const StyledButton = styled(Clickable).withConfig({
  shouldForwardProp: preventPropForwarding("hasOnlyIcon")
})`
  display: inline-block;
  padding: 0.75em 1em;
  border: none;

  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  transition: box-shadow 0.15s;

  &:hover {
    ${css({boxShadow: "medium"})}
  }

  &:active {
    ${css({boxShadow: "inset"})}
  }

  ${basicVariants}
  ${dangerVariants}
  ${sizeVariants}

  &:disabled {
    cursor: not-allowed;
    ${disabledVariants}
    &:hover {
      box-shadow: none;
    }
  }

  ${compose(typography, space, layout, border)}

  & > svg {
    vertical-align: bottom;
    ${css({marginLeft: 2})}

    ${p =>
      p.hasOnlyIcon &&
      css({
        mx: "-0.25em"
      })}
  }
`

export const Button = ({children, ...optional}) => {
  const {icon, ...props} = optional
  const hasOnlyIcon = Boolean(icon && !children)

  return (
    <StyledButton hasOnlyIcon={hasOnlyIcon} {...props}>
      {children}
      {icon}
    </StyledButton>
  )
}

Button.propTypes = {
  /** Button variant */
  variant: PropTypes.oneOf(["primary", "secondary"]),
  /** Use alternate coloring that signifies a potentially dangerous action */
  danger: PropTypes.bool,
  /** Use smaller version of button, useful when space is limited */
  small: PropTypes.bool,
  /** Use a larger, CTA-style version of button */
  large: PropTypes.bool,
  icon: PropTypes.element,
  type: PropTypes.oneOf(["submit", "button"])
}

Button.defaultProps = {
  variant: "primary",
  danger: false,
  small: false,
  large: false
}

export const ButtonsContainer = styled.div`
  ${StyledButton} + ${StyledButton} {
    ${css({
      ml: 3
    })}
  }

  ${compose(space, layout)}
`
