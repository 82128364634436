import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import css from "@styled-system/css"
import {position} from "styled-system"
import {Clickable} from "../input"
import {Logo} from "../dataDisplay/logo"

const StyledAppHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${css({
    pl: [4],
    height: ["auto", "3rem"]
  })}

  ${p =>
    // @ts-ignore
    !p.transparent &&
    css({
      pl: [4],
      boxShadow: "light",
      backgroundColor: "bg"
    })}
`

const AppHeaderItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const AppHeaderItem = styled.li`
  display: inline-block;
  height: 100%;
  border-left: 1px solid;

  ${css({
    borderColor: "border"
  })}

  &:only-child {
    border: 0;
  }
`

const StyledAppHeaderButton = styled(Clickable)`
  ${css({
    color: "appHeaderText",
    px: [2, 4],
    py: [2, 3]
  })}

  min-width: 3.5rem;

  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  transition: color 0.15s;

  & > svg {
    vertical-align: middle;
    transition: color 0.15s;

    ${p =>
      css({
        fontSize: [2],
        margin: p.alwaysHorizontal
          ? "-2px 0.5rem 0 0"
          : ["0 0 0.25em 0", "-2px 0.5rem 0 0"]
      })}
  }

  div {
    ${p =>
      css({
        display: p.alwaysHorizontal ? "inline" : ["block", "inline"],
        fontSize: p.alwaysHorizontal ? 0 : ["0.5rem", 0]
      })}
  }

  &:hover svg {
    ${css({color: "brand"})}
  }

  ${position}
`

export const AppHeaderButton = ({
  children,
  icon,
  onClick,
  alwaysHorizontal,
  ...props
}) => (
  <AppHeaderItem {...props}>
    <StyledAppHeaderButton
      onClick={onClick}
      alwaysHorizontal={alwaysHorizontal}
    >
      {icon}
      <div>{children}</div>
    </StyledAppHeaderButton>
  </AppHeaderItem>
)

AppHeaderButton.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  alwaysHorizontal: PropTypes.bool
}

export const AppHeader = ({children, ...optional}) => {
  const {noLogo, ...props} = optional

  return (
    <StyledAppHeader {...props}>
      <div>
        {!noLogo && (
          <a href="/">
            <Logo
              // @ts-ignore
              maxWidth="5rem"
            />
          </a>
        )}
      </div>

      <AppHeaderItems>{children}</AppHeaderItems>
    </StyledAppHeader>
  )
}

AppHeader.propTypes = {
  /** Don't render the logo  */
  noLogo: PropTypes.bool,
  /** Hide the background and shadow */
  transparent: PropTypes.bool
}

AppHeader.defaultProps = {
  noLogo: false,
  transparent: false
}
