import "@frontai/atlas/utils/polyfill"
import React from "react"
import {render} from "react-dom"
import {Provider} from "react-redux"
import {store} from "./store"
import {Portal} from "./views/portal/portal"

const App = () => (
  <Provider store={store}>
    <Portal />
  </Provider>
)

const root = document.createElement("div")
root.classList.add("js-atlas-root")
document.body.appendChild(root)
document.body.classList.add("js-atlas-top-frame")
render(<App />, root)
