import {groupProjectsByOrganization} from "@frontai/atlas/features/user-session/user-session.utils"
import {
  Clickable,
  Container,
  Heading,
  Image,
  Panel
} from "@frontai/design-system"
import css from "@styled-system/css"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    padding: 0;
  }
`

const StyledCard = styled.article`
  position: relative;

  min-height: 4rem;
  padding: 0.5rem 0.5rem 0.5rem 4.5rem;

  text-align: left;
  ${css({
    boxShadow: "veryLight",
    color: "text"
  })}

  transition: box-shadow 0.25s;

  &:hover {
    cursor: pointer;
    ${css({boxShadow: "light"})}
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;

    width: 4rem;
    height: 4rem;
  }
`

const Card = ({imgSrc, imgAlt, children, ...props}) => (
  <StyledCard {...props}>
    <Image src={imgSrc} alt={imgAlt} />
    {children}
  </StyledCard>
)

export const ProjectSelection = ({projects}) => {
  const organizations = groupProjectsByOrganization(projects)

  return (
    <Container
      // @ts-ignore
      maxWidth="modalMax"
      my="6"
      px={[2, 0]}
      textAlign="center"
    >
      <Panel>
        <Heading level="1" variant={[3, 2]}>
          Select Project
        </Heading>

        {organizations.map(organization => (
          <section key={organization.org_id}>
            <Heading level={2} variant={[4]} textAlign="left">
              {organization.org_name}
            </Heading>

            <UnstyledList data-cy="projects">
              {organization.projects.map(project => (
                <li key={project.project_id}>
                  <Clickable
                    display="block"
                    width={[1, 1]}
                    mb={3}
                    onClick={() => selectProject(project)}
                    data-cy="project"
                  >
                    <Card imgAlt="" imgSrc={project.project_logo_url}>
                      <Heading level={3} variant={5} my={1}>
                        {project.project_name}
                      </Heading>
                      {project.project_description}
                    </Card>
                  </Clickable>
                </li>
              ))}
            </UnstyledList>
          </section>
        ))}
      </Panel>
    </Container>
  )
}

ProjectSelection.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired
}
