import {endpoints} from "@frontai/atlas/settings"
import {
  AppMenu,
  AppMenuSection,
  Button,
  monochromeIcons,
  Paragraph
} from "@frontai/design-system"
import localforage from "localforage"
import PropTypes from "prop-types"
import React from "react"
import {AppList} from "../app-list/app-list"

export const MainMenu = ({
  user,
  project,
  apps,
  onClose,
  onClickChangeProject
}) => {
  const logout = async () => {
    await localforage.removeItem("project")
    await localforage.removeItem("mockAuthToken")
    await fetch(endpoints.idp.expire, {method: "POST"})
    location.href = "/"
  }

  return (
    <AppMenu top="4rem" maxHeight="calc(100vh - 5rem)" onClose={onClose}>
      <AppMenuSection data-cy="user-details">
        <Paragraph fontSize="3" my={1}>
          {user.name}
        </Paragraph>

        <Paragraph fontSize="0" my="1">
          <strong style={{textTransform: "uppercase", marginRight: "0.25rem"}}>
            E-MAIL
          </strong>{" "}
          {user.email}
        </Paragraph>

        <Paragraph fontSize="0" my="1">
          <strong style={{textTransform: "uppercase", marginRight: "0.25rem"}}>
            ORGANIZATION
          </strong>{" "}
          {user.org_name}
        </Paragraph>
      </AppMenuSection>

      {project && (
        <>
          <AppMenuSection
            title="Project"
            headingLevel={3}
            icon={<monochromeIcons.User />}
            onClick={onClickChangeProject}
            buttonText="Change"
            data-cy="project-details"
          >
            <Paragraph fontSize="0" my="1">
              <strong
                style={{textTransform: "uppercase", marginRight: "0.25rem"}}
              >
                PROJECT
              </strong>{" "}
              {project.project_name}
            </Paragraph>
          </AppMenuSection>
          {apps && (
            <AppMenuSection
              icon={<monochromeIcons.Apps />}
              title="Apps"
              data-cy="app-details"
            >
              <AppList apps={apps} />
            </AppMenuSection>
          )}
        </>
      )}

      <AppMenuSection py="3" textAlign="center">
        <Button variant="secondary" danger onClick={logout} data-cy="log-out">
          Log out
        </Button>
      </AppMenuSection>
    </AppMenu>
  )
}

MainMenu.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  apps: PropTypes.arrayOf(PropTypes.object)
}
