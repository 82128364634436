/**
 * Return higher order function, which allows only one call to _func_, in
 * _tresholdMs_ milliseconds. Repeated calls within that treshold are ignored.
 *
 * @param {number} tresholdMs
 * @param {Function} func
 */
export const throttle = (tresholdMs, func) => {
  let t0

  return (...args) => {
    const t1 = performance.now()
    if (!t0 || t1 - t0 > tresholdMs) {
      t0 = t1
      func(...args)
    }
  }
}
