const colorNames = {
  black: "#000",
  white: "#FFF",
  grey0: "#333333",
  grey1: "#4F4F4F",
  grey2: "#828282",
  grey3: "#BDBDBD",
  grey4: "#E5E5E5",
  grey5: "#F6F6F6",
  red: "#C70017",
  green: "#00C7B1",
  blue: "#1455CD",
  orange: "#FF8A00"
}

export const modes = {
  light: {
    name: "light",
    logoSrc: "/assets/frontai-logo-light.png",
    backgroundSrc: "/assets/bg-light.jpg",
    colors: {
      brand: colorNames.green,
      accent: colorNames.red,
      info: colorNames.blue,
      success: colorNames.green,
      warning: colorNames.orange,
      danger: colorNames.red,
      disabled: colorNames.grey4,
      bg: colorNames.white,
      text: colorNames.black,
      altText: colorNames.white,
      link: colorNames.blue,
      border: colorNames.grey4,
      borderStrong: colorNames.grey2,
      input: colorNames.grey1,
      placeholder: colorNames.grey2,
      appHeaderText: colorNames.grey1,
      appMenuTitle: colorNames.grey2,
      tableAltBg: colorNames.grey5,
      iconOutline: colorNames.black,
      jsonEditorHeader: colorNames.green,
      jsonEditorMenu: colorNames.grey5,
      jsonEditorText: colorNames.grey1
    },
    shadows: {
      veryLight: "0 0 0.1rem rgba(0, 0, 0, 0.5)",
      light: "0 0 0.25rem rgba(0, 0, 0, 0.4)",
      medium: "0 0.075rem 0.25rem rgba(0, 0, 0, 0.5)",
      heavy: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.35)",
      inset: "inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5)",
      focus: "0 0 0.25rem " + colorNames.green
    },
    plugins: {
      jsonEditor: {
        theme: "ace/theme/chrome"
      }
    }
  },
  dark: {
    mode: "dark",
    logoSrc: "/assets/frontai-logo-dark.png",
    backgroundSrc: "/assets/bg-dark.jpg",
    colors: {
      brand: colorNames.green,
      accent: colorNames.red,
      info: colorNames.blue,
      success: colorNames.green,
      warning: colorNames.orange,
      danger: colorNames.red,
      disabled: colorNames.grey4,
      bg: colorNames.grey0,
      text: colorNames.white,
      altText: colorNames.white,
      link: colorNames.blue,
      border: colorNames.grey1,
      borderStrong: colorNames.gre3,
      input: colorNames.grey2,
      placeholder: colorNames.grey3,
      appHeaderText: colorNames.grey3,
      appMenuTitle: colorNames.grey3,
      tableAltBg: colorNames.grey1,
      iconOutline: colorNames.grey4,
      jsonEditorHeader: colorNames.green,
      jsonEditorMenu: colorNames.grey0,
      jsonEditorText: colorNames.grey4
    },
    shadows: {
      veryLight: "0 0 0.1rem rgba(0, 0, 0, 0.6)",
      light: "0 0 0.25rem rgba(0, 0, 0, 0.5)",
      medium: "0 0.075rem 0.25rem rgba(0, 0, 0, 0.6)",
      heavy: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.45)",
      inset: "inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.6)",
      focus: "0 0 0.25rem " + colorNames.green
    },
    plugins: {
      jsonEditor: {
        theme: "ace/theme/monokai"
      }
    }
  }
}

export const theme = {
  ...modes.light,
  breakpoints: ["40em", "52em", "64em", "80em"],
  baseFontSize: "16px",
  fontSizes: ["0.75rem", "1rem", "1.125rem", "1.5rem", "1.875rem", "2.25em"],
  fonts: {
    body: "Lato, sans-serif",
    heading: "Lato, sans-serif",
    code: "'Source Code Pro', monospace"
  },
  lineHeights: {
    body: "1.5",
    heading: "1.2"
  },
  sizes: {
    appMax: "80rem",
    modalMax: "30rem",
    toasterMax: "35rem",
    dialogMax: "25rem",
    appMenuMax: "20rem"
  },
  space: [
    0,
    "0.25rem",
    "0.5rem",
    "1rem",
    "1.5rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "10rem"
  ],
  radii: {
    input: 2,
    panel: 4,
    cta: "5em"
  },
  zIndices: {
    default: 0,
    menu: 10,
    overlay: 100,
    modal: 1000,
    toast: 10000
  }
}
