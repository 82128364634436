import styled from "styled-components"
import {layout, compose, typography, space, color} from "styled-system"
import css from "@styled-system/css"

export const Label = styled.label`
  display: inline-block;

  ${css({
    fontSize: 1,
    lineHeight: "body",
    fontFamily: "body"
  })}

  ${compose(typography, space, color, layout)}
`
