import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {compose, typography, space, layout, border} from "styled-system"
import css from "@styled-system/css"

const StyledTextField = styled.input`
  display: inline-block;
  transition: all 0.15s;
  border: 1px solid;

  ${css({
    width: ["100%", "auto"],
    minWidth: 9,
    padding: [2],
    color: "text",
    bg: "bg",
    borderRadius: "input",
    borderColor: "input"
  })}

  ${p =>
    // @ts-ignore: TS can't infer props correctly without typings
    p.error &&
    css({
      borderColor: "danger",
      color: "danger"
    })}

  &::placeholder {
    ${css({color: "placeholder"})}
  }

  &:focus {
    ${css({
      borderColor: "brand",
      boxShadow: "focus"
    })}
  }

  &:disabled {
    ${css({
      color: "input",
      borderColor: "input",
      opacity: 0.25,
      cursor: "not-allowed"
    })}
  }

  ${compose(typography, space, layout, border)}
`

export const TextField = props => <StyledTextField {...props} />

TextField.propTypes = {
  /** Input type */
  type: PropTypes.oneOf(["text", "email", "password", "number"]).isRequired,
  /** Does the field have an error */
  // @ts-ignore: TS can't infer props correctly without typings
  error: PropTypes.bool
}

TextField.defaultProps = {
  //@ts-ignore: TS can't infer props correctly without typings
  error: false,
  type: "text"
}
