import {useEffect} from "react"

export const MESSAGING_ORIGIN = "*"

export const messageTypes = {
  READY: "READY",
  UPDATE_COLOR_MODE: "UPDATE_COLOR_MODE",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  UPDATE_APP_CONFIG: "UPDATE_APP_CONFIG",
  NOTIFY_USER: "NOTIFY_USER"
}

export const sendMessage = (frame, type, payload) => {
  if (!frame || !frame.hasOwnProperty("postMessage")) {
    console.warn("Failed to call postMessage on " + frame)
  }

  frame.postMessage({type, payload}, MESSAGING_ORIGIN)
}

export const useMessaging = (
  onReceiveMessage,
  onStartListening,
  onStopListening
) => {
  useEffect(() => {
    const handleReceiveMessage = ({data: {type, payload}}) => {
      if (Object.values(messageTypes).includes(type)) {
        onReceiveMessage(type, payload)
      }
    }

    window.addEventListener("message", handleReceiveMessage)
    onStartListening && onStartListening()

    return () => {
      window.removeEventListener("message", handleReceiveMessage)
      onStopListening && onStopListening()
    }
  }, [])
}
