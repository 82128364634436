import {getColorMode} from "@frontai/atlas/features/app-configuration/app-configuration.store"
import {getAppById} from "@frontai/atlas/features/embedded-app/embedded-app.utils"
import {
  useFrameSrc,
  useSyncFrameParameters,
  useSyncFrameLocation,
  useReceiveFrameNotifications
} from "@frontai/atlas/features/embedded-app/embedded-app.hooks"
import PropTypes from "prop-types"
import React, {useRef} from "react"
import {useSelector} from "react-redux"
import styled from "styled-components"

const Frame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;

  box-sizing: content-box;
  width: 100%;
  height: 100%;
  padding-top: 3rem;

  border: none;
`

export const AtlasApp = ({apps, match, location, history}) => {
  const {id} = match.params
  const frameRef = useRef(null)
  const app = getAppById(apps, id)
  const src = useFrameSrc(app, location)
  const colorMode = useSelector(getColorMode)
  useSyncFrameParameters(frameRef.current, app, colorMode)
  useSyncFrameLocation(app, history, location)
  useReceiveFrameNotifications()
  return <Frame ref={frameRef} src={src} data-cy="embedded-app" />
}

AtlasApp.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  apps: PropTypes.arrayOf(PropTypes.object).isRequired
}
