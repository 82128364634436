import styled from "styled-components"
import {
  background,
  compose,
  layout,
  position,
  space,
  typography
} from "styled-system"

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  width: 100%;

  ${compose(layout, space, background, position, typography)}
`

Container.defaultProps = {
  // @ts-ignore: TS does not understand styled-system props without typings
  py: 0,
  px: [2, 3],
  maxWidth: "appMax"
}
