import * as R from "ramda"

export const fromTheme = (...args) => R.path(["theme", ...args])

/**
 * Helper function to access theme parameters from custom props. Supports Theme
 * UI style scales (https://system-ui.com/theme/).
 *
 * Example:
 * // Theme
 * {colors: {blues: [#00f]}}
 * // Component
 * const SVG = styled.svg`
 *  fill: ${propFromScale("color1", "colors")};
 * // Usage
 * <SVG color1="blues.0" />
 *
 * @param {string} propName
 * @param {string} scaleName
 * @param {object} props
 */
export const propFromScale = R.curry((propName, scaleName, props) => {
  const selector = props[propName]
  const scale = props.theme[scaleName]

  if (!scale) {
    return selector
  }

  return R.pathOr(selector, selector.split("."), scale)
})

/**
 * Takes a list of Styled System functions (functions with own property "props",
 * which is a list of strings) and an object. Picks the key-value pairs from the
 * object, where the keys are props defined by the styled-system apis.
 *
 * ([background, spacing], {a: 1, px: 1, bg: "black", foo: 'bar')
 * ->
 * {px: 1, bg: "black"}
 *
 * @param {Function[]} styledSystemApis
 * @param {object} obj
 */
export const pickStyleSystemProps = R.curry((styledSystemApis, obj) => {
  const props = styledSystemApis.flatMap(api => api.props)
  return R.pick(props, obj)
})

/**
 * Takes a list of Styled System functions (functions with own property "props",
 * which is a list of strings) and an object. Omits the key-value pairs from the
 * object, where the keys are props defined by the styled-system apis.
 *
 * ([background, spacing], {a: 1, px: 1, bg: "black", foo: 'bar')
 * ->
 * {a: 1, foo: 'bar'}
 *
 * @param {Function[]} styledSystemApis
 * @param {object} obj
 */
export const omitStyleSystemProps = R.curry((styledSystemApis, obj) => {
  const props = styledSystemApis.flatMap(api => api.props)
  return R.omit(props, obj)
})

/**
 * Utility function to filter props passed to HTML-elements in a less verbose
 * manner.
 *
 * styled.div.withConfig({shouldForwardProp: preventPropForwarding("foo")})``
 *
 * @param  {...string} props
 */
export const preventPropForwarding = (...props) => (prop, defaultValidatorFn) =>
  !props.includes(prop) && defaultValidatorFn(prop)

export const colorModeToDataTableTheme = mode => ({
  text: {
    primary: mode.colors.brand,
    secondary: mode.colors.accent
  },
  background: {
    default: mode.colors.bg
  },
  context: {
    background: mode.colors.accent,
    text: mode.colors.altText
  },
  divider: {
    default: mode.colors.border
  }
})
