import React from "react"
import styled from "styled-components"
import {variant, compose, typography, space, color} from "styled-system"
import css from "@styled-system/css"
import PropTypes from "prop-types"

const StyledParagraph = styled.p`
  ${css({
    fontSize: 1,
    lineHeight: "body",
    fontFamily: "body"
  })}

  ${variant({
    variants: {
      lead: {
        fontSize: 2
      },
      small: {
        fontSize: 0
      }
    }
  })}

  ${compose(typography, space, color)}
`

export const Paragraph = ({children, ...props}) => (
  <StyledParagraph {...props}>{children}</StyledParagraph>
)

Paragraph.propTypes = {
  variant: PropTypes.oneOf(["lead", "small"])
}
