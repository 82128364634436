import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import css from "@styled-system/css"
import {Modal} from "../layout/modal"
import {Link} from "./link"
import {Heading} from "../typography"
import {compose, typography, layout, space} from "styled-system"
import {Clickable} from "../input/clickable"

const AppMenuItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const AppMenuItem = styled.li`
  & + & {
    border-top: 1px solid;
    ${css({borderColor: "border"})}
  }

  & > section,
  & > a {
    display: block;
    width: 100%;
  }

  & > section {
    ${css({px: 3, pt: 2, pb: 3})}
  }

  & > a {
    ${css({px: 3, py: 3})}
  }

  ${compose(layout, space, typography)}
`

const SectionHeading = styled(Heading)`
  & > svg {
    margin: -2px 0.5em 0 0;
    vertical-align: middle;
  }

  & > button {
    font-size: 1em;
    font-weight: bold;
    margin-left: 0.35rem;
    text-transform: uppercase;
    ${css({color: "info"})}
  }
`

export const AppMenuSection = ({children, ...optional}) => {
  const {headingLevel, title, icon, buttonText, onClick, ...props} = optional

  return (
    <AppMenuItem {...props}>
      <section>
        {title && (
          <SectionHeading
            fontSize={0}
            mt={2}
            mb={3}
            color="appMenuTitle"
            level={headingLevel}
          >
            {icon} {title}
            {onClick && buttonText && (
              <Clickable onClick={onClick}>({buttonText})</Clickable>
            )}
          </SectionHeading>
        )}
        {children}
      </section>
    </AppMenuItem>
  )
}

AppMenuSection.propTypes = {
  headingLevel: PropTypes.number,
  icon: PropTypes.element,
  title: PropTypes.string
}

AppMenuSection.defaultProps = {
  headingLevel: 3
}

export const AppMenuLink = ({to, href, children, ...props}) => (
  <AppMenuItem {...props}>
    <Link href="#">{children}</Link>
  </AppMenuItem>
)

AppMenuLink.propTypes = {
  /** Link target for internal links */
  to: PropTypes.string,
  /** Link target for external links */
  href: PropTypes.string
}

export const AppMenu = ({children, ...props}) => (
  <Modal
    enableBodyScroll
    hideOverlay
    width={["calc(100% - 1rem)", "appMenuMax"]}
    padding={[0, 0]}
    right={["0.5rem", "1rem"]}
    left={["0.5rem", "auto"]}
    {...props}
  >
    <nav>
      <AppMenuItems>{children}</AppMenuItems>
    </nav>
  </Modal>
)

AppMenu.propTypes = {
  /** Callback launched when user clicks outside of the menu */
  onClose: PropTypes.func,
  /** Offset from top of the viewport */
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

AppMenu.defaultProps = {
  top: "1rem"
}
