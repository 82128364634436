import css from "@styled-system/css"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  background,
  compose,
  flexbox,
  layout,
  position,
  shadow,
  space
} from "styled-system"

export const PanelDivider = styled.div`
  width: 100%;
  height: 1px;
  ${css({
    my: [2]
  })}

  &::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;

    border: 1px solid;

    ${css({
      borderColor: "border"
    })}
  }
`

export const Panel = styled.div`
  ${css({
    padding: [3],
    borderRadius: "panel",
    backgroundColor: "bg"
  })}

  ${compose(layout, space, background, shadow, flexbox, position)}
`

Panel.propTypes = {
  // @ts-ignore: TS does not understand styled-system props without typings
  boxShadow: PropTypes.oneOf(["light", "medium", "heavy"])
}

Panel.defaultProps = {
  // @ts-ignore: TS does not understand styled-system props without typings
  boxShadow: "light"
}
