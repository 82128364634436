import React, {memo, useEffect, useRef} from "react"
import {createPortal} from "react-dom"
import PropTypes from "prop-types"
import styled, {
  css as styledCSS,
  createGlobalStyle,
  keyframes
} from "styled-components"
import {position} from "styled-system"
import css from "@styled-system/css"
import {Panel} from "./panel"
import {appear, blur} from "../core/keyframes"

const ESC_KEY_CODE = 27

const appearUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50%, 0%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
`

const appearDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const StyledModal = styled(Panel)`
  position: fixed;
  display: inline-block;
  overflow-y: auto;

  ${css({zIndex: "modal", boxShadow: "heavy"})}

  ${p =>
    // @ts-ignore: TS can't infer this prop
    p.center
      ? styledCSS`
      left: 50%;
      top: 50%;
      animation: ${appearUp} 0.5s;
    `
      : styledCSS`
      animation: ${appearDown} 0.5s;
    `}

  animation-fill-mode: forwards;

  ${position}
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);

  animation: ${appear} 0.25s;

  ${css({zIndex: "overlay"})}
`

const HideBodyOverflow = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`

const BackgroundBlur = createGlobalStyle`
  body > div:not(.js-atlas-modal) {
    animation: ${blur} 0.25s;
    animation-fill-mode: forwards;
  }
`

/**
 * Create a portal to render modals as direct children of body.
 */
export const ModalPortal = memo(({children}) => {
  const element = useRef(document.createElement("div"))
  element.current.classList.add("js-atlas-modal")

  useEffect(() => {
    document.body.prepend(element.current)
    return () => {
      element.current.parentElement.removeChild(element.current)
    }
  }, [])

  return createPortal(children, element.current)
})

export const Modal = ({onClose, children, ...optional}) => {
  const {enableBodyScroll, hideOverlay, ...props} = optional

  const modalElement = useRef(null)

  /**
   * Set focus to the first focusable element in the modal when it opens
   */
  useEffect(() => {
    if (modalElement.current) {
      const focusable = modalElement.current.querySelector(
        "a, button, input, [tabindex]"
      )
      focusable && focusable.focus()
    }
  }, [modalElement.current])

  /**
   * Close the modal when user clicks outside of the modal element
   */
  useEffect(() => {
    if (onClose) {
      const handleClickEvent = event => {
        if (
          modalElement.current &&
          !modalElement.current.contains(event.target)
        ) {
          onClose()
        }
      }

      document.body.addEventListener("click", handleClickEvent)
      // Blur event on window is triggered when user clicks an iframe
      window.addEventListener("blur", onClose)

      return () => {
        document.body.removeEventListener("click", handleClickEvent)
        window.removeEventListener("blur", onClose)
      }
    }
  }, [])

  /**
   * Close the modal when ESC key is pressed
   */
  useEffect(() => {
    if (onClose) {
      const handleKeyUpEvent = event => {
        if (event.keyCode === ESC_KEY_CODE) {
          onClose()
        }
      }

      window.addEventListener("keyup", handleKeyUpEvent)

      return () => {
        window.removeEventListener("keyup", handleKeyUpEvent)
      }
    }
  }, [])

  return (
    <ModalPortal>
      {!enableBodyScroll && <HideBodyOverflow />}
      {!hideOverlay && (
        <>
          <BackgroundBlur />
          <Overlay />
        </>
      )}
      <StyledModal ref={modalElement} {...props}>
        {children}
      </StyledModal>
    </ModalPortal>
  )
}

Modal.propTypes = {
  /** When true, the overlay and blur effect are not rendered */
  hideOverlay: PropTypes.bool,
  /** When true, the modal is centered vertically and horizontally */
  center: PropTypes.bool,
  /** When true, enables scrolling of document body when the modal is visible */
  enableBodyScroll: PropTypes.bool,
  /**
   * Called when user attempts to close the modal via mouse or keyboard.
   * If not given, modal closing must be handled outside the component.
   */
  onClose: PropTypes.func
}

Modal.defaultProps = {
  hideOverlay: false,
  center: false,
  enableBodyScroll: false
}
