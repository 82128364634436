import {
  AppHeader,
  AppHeaderButton,
  monochromeIcons
} from "@frontai/design-system"
import React from "react"

export const UnauthenticatedHeader = ({toggleColorMode}) => (
  <AppHeader noLogo transparent>
    <AppHeaderButton
      icon={<monochromeIcons.LightSwitch />}
      onClick={toggleColorMode}
      alwaysHorizontal
      data-cy="change-theme"
    >
      Lights
    </AppHeaderButton>
  </AppHeader>
)
