import {createGlobalStyle} from "styled-components"
import {appear} from "./keyframes"

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    min-height: 100vh;
  }

  body {
    font-size: ${p => p.theme.baseFontSize};
    font-family: ${p => p.theme.fonts.body};

    color: ${p => p.theme.colors.text};
    background: url(${p => p.theme.backgroundSrc});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    transition: background 0.25s, background-position 0s;
    animation: 1s ${appear};

  }
`
