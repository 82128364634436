/**
 * This store contains basic configuration a single app
 */

import {createAction, handleActions, combineActions} from "redux-actions"
import {createSelector} from "reselect"

/**
 * Actions
 */

export const updateBaseConfiguration = createAction(
  "UPDATE_BASE_CONFIGURATION",
  baseConfig => ({baseConfig})
)
export const updateAppConfiguration = createAction(
  "UPDATE_APP_CONFIGURATION",
  appConfig => ({appConfig})
)
export const updateColorMode = createAction("UPDATE_COLOR_MODE", mode => ({
  mode
}))
export const updateQueryString = createAction(
  "UPDATE_QUERY_STRING",
  queryString => ({queryString})
)

/**
 * Reducer
 */

export const embeddedAppReducer = handleActions(
  {
    [combineActions(
      updateBaseConfiguration,
      updateAppConfiguration,
      updateQueryString,
      updateColorMode
    )]: (state, {payload, error}) => {
      return {...state, ...payload, hasError: error}
    }
  },
  {}
)

/**
 * Selectors
 */

export const getEmbeddedAppState = state => state.embeddedAppReducer
export const getAppConfig = createSelector(
  getEmbeddedAppState,
  config => config.appConfig
)
export const getColorMode = createSelector(
  getEmbeddedAppState,
  config => config.mode
)
export const getBaseConfig = createSelector(
  getEmbeddedAppState,
  config => config.baseConfig
)
export const getQueryString = createSelector(
  getEmbeddedAppState,
  config => config.queryString
)
